export const FILTERS_TYPES = {
  TEXT: 'TEXT',
  NUMERIC: 'NUMERIC',
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
}

export const normalizeDateTo = (date: string) => {
  return `${date.split('T')[0]}T00:00:00.000Z`
}

export const normalizeDateToEnd = (date: string) => {
  return `${date.split('T')[0]}T23:59:59.999Z`
}

const getTextMatchModeToQuery = (property: string, value: string, matchMode: string): string => {
  if (value === null) return '';

  if (matchMode === "startsWith") {
    return `${property}:'${value}*'`;
  }
  else if (matchMode === "endsWith") {
    return `${property}:'*${value}'`;
  }
  else if (matchMode === "contains") {
    return `${property}:'*${value}*'`;
  }
  else if (matchMode === "notContains") {
    return `${property}!'*${value}'`;
  }
  else if (matchMode === "equals") {
    return `${property}:'${value}'`;
  }
  else if (matchMode === "notEquals") {
    return `${property}!'${value}'`;
  }
  else if (matchMode === "lt") {
    return `${property}<'${value}'`;
  }
  else if (matchMode === "lte") {
    return `${property}:'${value}' OR ${property}<'${value}'`;
  }
  else if (matchMode === "lt") {
    return `${property}<'${value}'`;
  }
  else if (matchMode === "lte") {
    return `${property}:'${value}' OR ${property}<'${value}'`;
  }

  return '';
}

const getNumericMatchModeToQuery = (property: string, value: number, matchMode: string): string => {
  if (value === null) return '';

  if (matchMode === "equals") {
    return `${property}:${value}`;
  }
  else if (matchMode === "notEquals") {
    return `${property}!${value}`;
  }
  else if (matchMode === "lt") {
    return `${property}<${value}`;
  }
  else if (matchMode === "lte") {
    return `${property}:${value} OR ${property}<${value}`;
  }
  else if (matchMode === "gt") {
    return `${property}>${value}`;
  }
  else if (matchMode === "gte") {
    return `${property}:${value} OR ${property}>${value}`;
  }

  return '';
}

const getDateMatchModeToQuery = (property: string, value: Date, matchMode: string): string => {
  if (value === null) return '';

  if (matchMode === "dateIs") {
    return `(${property}>'${normalizeDateTo(value.toISOString())}' OR ${property}:'${normalizeDateTo(value.toISOString())}') AND (${property}<'${normalizeDateToEnd(value.toISOString())}' OR ${property}:'${normalizeDateToEnd(value.toISOString())}')`;
  }
  else if (matchMode === "dateIsNot") {
    return `${property}!'${value.toISOString().split('T')[0]}*'`;
  }
  else if (matchMode === "dateBefore") {
    return `${property}<'${value.toISOString()}'`;
  }
  else if (matchMode === "dateAfter") {
    return `${property}>'${value.toISOString()}'`;
  }

  return '';
}

const getBooleanMatchModeToQuery = (property: string, value: boolean, matchMode: string): string => {
  if (value === null) return '';

  if (matchMode === "contains") {
    return `${property}:'${value}'`;
  }
  return '';
}

export const filtersToQuery = (rules: any, name: string, fieldTypeMapper: any): string => {
  if (!Array.isArray(rules)) return '';
  const queryString = [];
  let hasRule = false;
  for (const rule of rules) {
    if (rule.value === null) continue;

    const type = fieldTypeMapper[name];
    let result: string = '';
    if (type === FILTERS_TYPES.TEXT) {
      result = getTextMatchModeToQuery(name, rule.value, rule.matchMode);
    }
    else if (type === FILTERS_TYPES.NUMERIC) {
      result = getNumericMatchModeToQuery(name, rule.value, rule.matchMode);
    }
    else if (type === FILTERS_TYPES.DATE) {
      result = getDateMatchModeToQuery(name, rule.value, rule.matchMode);
    }
    else if (type === FILTERS_TYPES.BOOLEAN) {
      result = getBooleanMatchModeToQuery(name, rule.value, rule.matchMode);
    }

    if (result) {
      if (hasRule) {
        if (hasRule && rule.operator === 'and') {
          queryString.push(' AND ');
        }
        else {
          queryString.push(' OR ');
        }
      }
      queryString.push(result);
      hasRule = true;
    }
  }
  if (queryString.length > 0) {
    queryString.unshift('(');
    queryString.push(')');
  }
  return queryString.join('');
}

export const globalFilterToQuery = (value: string, fieldTypeMapper: any): string => {
  if (!value) return '';
  const queryString = [];
  let hasRule = false;
  for (const key of Object.keys(fieldTypeMapper)) {
    if (fieldTypeMapper[key] !== FILTERS_TYPES.TEXT) continue;
    const result = getTextMatchModeToQuery(key, value, 'contains');
    if (result) {
      if (hasRule) {
        queryString.push(' OR ');
      }
      queryString.push(result);
    }
    if (queryString.length > 0) {
      hasRule = true;
    }
  }
  if (queryString.length > 0) {
    queryString.unshift('(');
    queryString.push(')');
  }
  return queryString.join('');
}

export const getSortFromQuery = (field: string, sort: number): string => {
  return `sort=${field},${sort === 1 ? 'asc' : 'desc'}`
}

export const createQuery = (event: any, fieldTypeMapper: any, defaultQuery: string = '') => {
  const querySearch:string[] = [];
  const filters = event.filters;
  let hasRule = false;
  for (const key of Object.keys(filters)) {
    const rules = filters[key];
    const result = filtersToQuery(rules, key, fieldTypeMapper);
    if (result) {
      if(hasRule) {
        querySearch.push(' AND ');
      }
      querySearch.push(result);
      hasRule = true;
    } 
  }

  const result = globalFilterToQuery(event.globalFilter?.value, fieldTypeMapper);
  if(result) {
    if (querySearch.length > 0) {
      querySearch.push(' AND ');
    }
    querySearch.push(result);
  }

  if (defaultQuery) {
    if (querySearch.length > 0) {
      querySearch.push(' AND ');
    }
    querySearch.push(encodeURIComponent(defaultQuery));
  }

  if (querySearch.length > 0) {
    querySearch.unshift('querySearch=');
    querySearch.push('&');
  }



  if (event.sortField) {
    querySearch.push(getSortFromQuery(event.sortField, event.sortOrder));
    querySearch.push('&');
  } else {
    querySearch.push('sort=createdOn,desc');
    querySearch.push('&');
  }

  return querySearch.join('');
}
