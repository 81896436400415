import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ConfirmationService,
  LazyLoadEvent,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { FILTERS_TYPES, createQuery } from 'src/app/utils/filter';
import { FormBuilder } from '@angular/forms';
import {
  ENTITY_TYPES,
  HUB_REPORT_STATUS,
  ENTITY_TYPES_ENUM,
} from 'src/app/utils/constants';

const fieldTypeMapper: any = {
  name: FILTERS_TYPES.TEXT,
  urlHandler: FILTERS_TYPES.TEXT,
  email: FILTERS_TYPES.TEXT,
  createdOn: FILTERS_TYPES.DATE,
};

@Component({
  selector: 'app-hub',
  templateUrl: './hub.component.html',
  styleUrls: ['./hub.component.scss'],
})
export class HubComponent implements OnInit {
  @ViewChild('itemsTable') public dataTable: Table | undefined;

  entityTypes = ENTITY_TYPES;
  reportStatus = HUB_REPORT_STATUS;
  statuses = Object.keys(HUB_REPORT_STATUS).map((key: string) => ({
    label: HUB_REPORT_STATUS[key],
    value: key,
  }));
  entityTypesOptions = Object.keys(ENTITY_TYPES).map((key: string) => ({
    label: ENTITY_TYPES[key],
    value: key,
  }));

  data = [];
  fa = [
    {
      content: {
        activityId: '8322d4dc-66d9-4bb1-853b-cf5cd2091567',
        activityName: 'Request',
        clientEmail: 'benjaminflopez@gmail.com',
        clientId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        clientName: 'Benjamin Lopez',
        clientPhoneNumber: '8299692443',
        clientProfileImage:
          'https://lh5.googleusercontent.com/-m503FHI5IEo/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucnWfVeCmTnWiiQNkvsGtI1R5GoB3A/s96-c/photo.jpg',
        createdOn: '2021-06-20T03:41:41.883Z',
        hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
        hubName: 'Cool Restaurant',
        hubUrlHandler: 'cool',
        id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
        location: null,
        ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        questionAnswerMap: {
          Image2: {
            choices: null,
            questionType: 'IMAGE',
            value:
              'https://res.cloudinary.com/diuejch4r/image/upload/q_auto/v1624252102/staging1/fyxkx6gjuafg5qob9nmi.jpg',
            values: {
              hidden: 'false',
            },
          },
        },
        shoppingList: [
          {
            amount: 1,
            itemOption: { description: 'Variant 1', price: '200' },
            productItemId: '3fbb1075-ce84-4528-abf9-8d49789bf2fe',
          },
        ],
        status: 'OPEN',
        totalPrice: '0',
        updatedOn: '2021-06-20T03:41:41.883Z',
      },
      contentType: 'REQUEST',
      createdOn: '2021-06-20T03:41:41.883Z',
      hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
      id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
      ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
      updatedOn: '2021-06-20T03:41:41.883Z',
    },
    {
      content: {
        activityId: '8322d4dc-66d9-4bb1-853b-cf5cd2091567',
        activityName: 'Request',
        clientEmail: 'benjaminflopez@gmail.com',
        clientId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        clientName: 'Benjamin Lopez',
        clientPhoneNumber: '8299692443',
        clientProfileImage:
          'https://lh5.googleusercontent.com/-m503FHI5IEo/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucnWfVeCmTnWiiQNkvsGtI1R5GoB3A/s96-c/photo.jpg',
        createdOn: '2021-06-20T03:41:41.883Z',
        hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
        hubName: 'Cool Restaurant',
        hubUrlHandler: 'cool',
        id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
        location: null,
        ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        questionAnswerMap: {
          Combo: {
            choices: null,
            questionType: 'SINGLE_CHOICE',
            value: 'Small',
            values: { hidden: 'true' },
          },
          Imagen: {
            choices: null,
            questionType: 'IMAGE',
            value:
              'https://res.cloudinary.com/diuejch4r/image/upload/q_auto/v1624160498/staging1/kyfyfi9ucrlwx0ngbfaj.png',
            values: { hidden: 'false' },
          },
          cvv: {
            choices: null,
            questionType: 'NUMBER',
            value: '34355',
            values: { hidden: 'true' },
          },
        },
        status: 'OPEN',
        totalPrice: '0',
        updatedOn: '2021-06-20T03:41:41.883Z',
      },
      contentType: 'REQUEST',
      createdOn: '2021-06-20T03:41:41.883Z',
      hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
      id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
      ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
      updatedOn: '2021-06-20T03:41:41.883Z',
    },
    {
      content: {
        activityId: '8322d4dc-66d9-4bb1-853b-cf5cd2091567',
        activityName: 'Request',
        clientEmail: 'benjaminflopez@gmail.com',
        clientId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        clientName: 'Benjamin Lopez',
        clientPhoneNumber: '8299692443',
        clientProfileImage:
          'https://lh5.googleusercontent.com/-m503FHI5IEo/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucnWfVeCmTnWiiQNkvsGtI1R5GoB3A/s96-c/photo.jpg',
        createdOn: '2021-06-20T03:41:41.883Z',
        hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
        hubName: 'Cool Restaurant',
        hubUrlHandler: 'cool',
        id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
        location: null,
        ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        questionAnswerMap: {},
        status: 'OPEN',
        totalPrice: '0',
        updatedOn: '2021-06-20T03:41:41.883Z',
      },
      contentType: 'REQUEST',
      createdOn: '2021-06-20T03:41:41.883Z',
      hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
      id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
      ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
      updatedOn: '2021-06-20T03:41:41.883Z',
    },
    {
      content: {
        activityId: '8322d4dc-66d9-4bb1-853b-cf5cd2091567',
        activityName: 'Request',
        clientEmail: 'benjaminflopez@gmail.com',
        clientId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        clientName: 'Benjamin Lopez',
        clientPhoneNumber: '8299692443',
        clientProfileImage:
          'https://lh5.googleusercontent.com/-m503FHI5IEo/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucnWfVeCmTnWiiQNkvsGtI1R5GoB3A/s96-c/photo.jpg',
        createdOn: '2021-06-20T03:41:41.883Z',
        hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
        hubName: 'Cool Restaurant',
        hubUrlHandler: 'cool',
        id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
        location: null,
        ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        questionAnswerMap: {},
        status: 'OPEN',
        totalPrice: '0',
        updatedOn: '2021-06-20T03:41:41.883Z',
      },
      contentType: 'REQUEST',
      createdOn: '2021-06-20T03:41:41.883Z',
      hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
      id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
      ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
      updatedOn: '2021-06-20T03:41:41.883Z',
    },
    {
      content: {
        activityId: '8322d4dc-66d9-4bb1-853b-cf5cd2091567',
        activityName: 'Request',
        clientEmail: 'benjaminflopez@gmail.com',
        clientId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        clientName: 'Benjamin Lopez',
        clientPhoneNumber: '8299692443',
        clientProfileImage:
          'https://lh5.googleusercontent.com/-m503FHI5IEo/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucnWfVeCmTnWiiQNkvsGtI1R5GoB3A/s96-c/photo.jpg',
        createdOn: '2021-06-20T03:41:41.883Z',
        hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
        hubName: 'Cool Restaurant',
        hubUrlHandler: 'cool',
        id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
        location: null,
        ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        questionAnswerMap: {},
        status: 'OPEN',
        totalPrice: '0',
        updatedOn: '2021-06-20T03:41:41.883Z',
      },
      contentType: 'REQUEST',
      createdOn: '2021-06-20T03:41:41.883Z',
      hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
      id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
      ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
      updatedOn: '2021-06-20T03:41:41.883Z',
    },
    {
      content: {
        activityId: '8322d4dc-66d9-4bb1-853b-cf5cd2091567',
        activityName: 'Request',
        clientEmail: 'benjaminflopez@gmail.com',
        clientId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        clientName: 'Benjamin Lopez',
        clientPhoneNumber: '8299692443',
        clientProfileImage:
          'https://lh5.googleusercontent.com/-m503FHI5IEo/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucnWfVeCmTnWiiQNkvsGtI1R5GoB3A/s96-c/photo.jpg',
        createdOn: '2021-06-20T03:41:41.883Z',
        hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
        hubName: 'Cool Restaurant',
        hubUrlHandler: 'cool',
        id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
        location: null,
        ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        questionAnswerMap: {},
        status: 'OPEN',
        totalPrice: '0',
        updatedOn: '2021-06-20T03:41:41.883Z',
      },
      contentType: 'REQUEST',
      createdOn: '2021-06-20T03:41:41.883Z',
      hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
      id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
      ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
      updatedOn: '2021-06-20T03:41:41.883Z',
    },
    {
      content: {
        activityId: '8322d4dc-66d9-4bb1-853b-cf5cd2091567',
        activityName: 'Request',
        clientEmail: 'benjaminflopez@gmail.com',
        clientId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        clientName: 'Benjamin Lopez',
        clientPhoneNumber: '8299692443',
        clientProfileImage:
          'https://lh5.googleusercontent.com/-m503FHI5IEo/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucnWfVeCmTnWiiQNkvsGtI1R5GoB3A/s96-c/photo.jpg',
        createdOn: '2021-06-20T03:41:41.883Z',
        hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
        hubName: 'Cool Restaurant',
        hubUrlHandler: 'cool',
        id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
        location: null,
        ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        questionAnswerMap: {},
        status: 'OPEN',
        totalPrice: '0',
        updatedOn: '2021-06-20T03:41:41.883Z',
      },
      contentType: 'REQUEST',
      createdOn: '2021-06-20T03:41:41.883Z',
      hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
      id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
      ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
      updatedOn: '2021-06-20T03:41:41.883Z',
    },
    {
      content: {
        activityId: '8322d4dc-66d9-4bb1-853b-cf5cd2091567',
        activityName: 'Request',
        clientEmail: 'benjaminflopez@gmail.com',
        clientId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        clientName: 'Benjamin Lopez',
        clientPhoneNumber: '8299692443',
        clientProfileImage:
          'https://lh5.googleusercontent.com/-m503FHI5IEo/AAAAAAAAAAI/AAAAAAAAAAA/AMZuucnWfVeCmTnWiiQNkvsGtI1R5GoB3A/s96-c/photo.jpg',
        createdOn: '2021-06-20T03:41:41.883Z',
        hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
        hubName: 'Cool Restaurant',
        hubUrlHandler: 'cool',
        id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
        location: null,
        ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
        questionAnswerMap: {},
        status: 'OPEN',
        totalPrice: '0',
        updatedOn: '2021-06-20T03:41:41.883Z',
      },
      contentType: 'REQUEST',
      createdOn: '2021-06-20T03:41:41.883Z',
      hubId: '2a5258fb-ca4b-4da0-841c-bf19d13c0cc3',
      id: '24b9a9cb-500f-4c9d-87bd-891832e9f721',
      ownerId: '7eNXDKMwDugenKqmHzpxCUsDyv73',
      updatedOn: '2021-06-20T03:41:41.883Z',
    },
  ];

  private readonly apiUrl = environment.apiUrl;
  private readonly appUrl = environment.appUrl;

  form: any;
  isLoadingForm: boolean = false;
  isDialogOpen: boolean = false;
  isEditMode: boolean = false;

  isLoading: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 15;
  firstInPage: number = 0;
  totalRecords: number = 200;
  lastQuery: string = '';
  selectedItem: any;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  cols = [
    { field: 'name', header: 'Hub Name' },
    { field: 'urlHandler', header: 'Hub Handler' },
    { field: 'email', header: 'Email' },
    { field: 'createdOn', header: 'Create On' },
    { field: 'active', header: 'Active' },
  ];

  ngOnInit() {
    this.form = this.fb.group({
      id: [''],
      urlHandler: [''],
      userId: [''],
      email: [''],
      name: [''],
      phoneNumber: [''],
      image: [''],
      icon: [''],
      infoText: [''],
      reviewRate: [null],
      reviewCount: [0],
      views: [0],
      active: [false],
      unlisted: [false],
      restricted: [false],
      updatedOn: [null],
      createdOn: [null],
    });
  }

  private setForm(item: any) {
    this.selectedItem = item;

    this.form.patchValue({ id: item.id });
    this.form.patchValue({ urlHandler: item.urlHandler });
    this.form.patchValue({ userId: item.userId });
    this.form.patchValue({ email: item.email });
    this.form.patchValue({ name: item.name });

    this.form.patchValue({ phoneNumber: item.phoneNumber });
    this.form.patchValue({ image: item.image });
    this.form.patchValue({ icon: item.icon });
    this.form.patchValue({ infoText: item.infoText });

    this.form.patchValue({ reviewRate: item.reviewRate });
    this.form.patchValue({ reviewCount: item.reviewCount });
    this.form.patchValue({ views: item.views });
    this.form.patchValue({ active: item.active });
    this.form.patchValue({ unlisted: item.unlisted });

    this.form.patchValue({
      createdOn: item.createdOn ? new Date(item.createdOn) : null,
    });
    this.form.patchValue({
      updatedOn: item.updatedOn ? new Date(item.updatedOn) : null,
    });

    this.form.markAsPristine();
  }

  hasFormErrors() {
    return !this.form.valid;
  }

  fieldErrors(field: string) {
    const controlState = this.form.controls[field];
    return controlState.dirty && controlState.errors
      ? controlState.errors
      : null;
  }

  dataNormalizer(data: any) {
    return data.map((item: any) => ({
      ...item.content,
      contentType: item.contentType,
    }));
  }

  loadData(event: LazyLoadEvent) {
    this.isLoading = true;
    this.firstInPage = event.first || 0;
    this.pageSize = event.rows || 0;
    this.pageIndex =
      this.pageSize > 0 ? Math.floor(this.firstInPage / this.pageSize) : 0;
    const querySearch: string = createQuery(event, fieldTypeMapper);
    const URL = `${this.apiUrl}/hubs/v1/search?${querySearch}page=${this.pageIndex}&size=${this.pageSize}`;
    this.lastQuery = URL;
    this.search(URL);
  }

  async search(url: string) {
    const el = document.getElementsByClassName('page-container')[0];
    if (el) {
      el.scrollTop = 0;
    }

    try {
      const response = await this.httpService.doGet(url).toPromise();
      if (response) {
        this.data = response.content;
        this.totalRecords = response.totalElements;
      } else {
        this.data = [];
        this.totalRecords = 0;
        this.firstInPage = 0;
        this.pageIndex = 0;
      }
      this.isLoading = false;
    } catch (error: any) {
      this.data = [];
      this.totalRecords = 0;
      this.firstInPage = 0;
      this.pageIndex = 0;
      this.isLoading = false;
      console.log('Error: ', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      });
    }
  }

  refreshTable() {
    this.search(this.lastQuery);
  }

  clear(table: Table) {
    table.clear();
  }

  openNew() {
    this.form.reset();
    this.isEditMode = true;
    this.isDialogOpen = true;
  }

  viewItem(item: any) {
    this.form.reset();
    this.setForm(item);
    this.form.disable();
    this.isEditMode = false;
    this.isDialogOpen = true;
  }

  editItem(item: any) {
    this.form.reset();
    this.setForm(item);
    this.form.disable();
    this.isEditMode = true;
    this.isDialogOpen = true;
  }

  tryToSaveItem() {
    this.confirmationService.confirm({
      message: 'Are you sure you want to save the changes?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.saveItem();
      },
    });
  }

  async saveItem() {
    this.isLoadingForm = true;
    const URL = `${this.apiUrl}/admin-center/abuse/v1`;

    try {
      await this.httpService
        .doPut(URL, {
          id: this.form.get('id').value,
          userId: this.form.get('userId').value,
          status: this.form.get('status').value,
        })
        .toPromise();
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Hub changes saved',
        life: 3000,
      });
      this.isLoadingForm = false;
      this.hideDialog();
      this.refreshTable();
    } catch (error: any) {
      this.isLoadingForm = false;
      console.log('Error: ', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      });
    }
  }

  hideDialog() {
    this.isDialogOpen = false;
    this.isEditMode = false;
  }

  getHubUrl(item: any) {
    return `${this.appUrl}/${item.urlHandler}/client-view?hubId=${item.id}`;
  }

  getEntityUrl(item: any) {
    switch (item.contentType) {
      case ENTITY_TYPES_ENUM.HUB:
        return `${this.appUrl}/${item.hubUrlHandler}/client-view?hubId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.ACTIVITY_INFO:
        return `${this.appUrl}/${item.hubUrlHandler}/actinfo/client-view?activityId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.REQUEST:
      case ENTITY_TYPES_ENUM.ACTIVITY_REQUEST:
        return `${this.appUrl}/${item.hubUrlHandler}/actrqt/client-view?activityId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.RESERVATION:
      case ENTITY_TYPES_ENUM.ACTIVITY_RESERVATION:
        return `${this.appUrl}/${item.hubUrlHandler}/actrvt/client-view?activityId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.SHOPPING:
      case ENTITY_TYPES_ENUM.ACTIVITY_PRODUCT_LISTING:
        return `${this.appUrl}/${item.hubUrlHandler}/actpl/client-view?activityId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.MENU_REQUES:
      case ENTITY_TYPES_ENUM.ACTIVITY_MENU:
        return `${this.appUrl}/${item.hubUrlHandler}/actmenu/client-view?activityId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.CATEGORY_ACCESS:
      case ENTITY_TYPES_ENUM.CATEGORY:
        return `${this.appUrl}/${item.hubUrlHandler}/category/client-view?categoryId=${item.categoryId}`;
    }
    return null;
  }
}
