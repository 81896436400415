import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ConfirmationService,
  LazyLoadEvent,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { FILTERS_TYPES, createQuery } from 'src/app/utils/filter';
import { FormBuilder } from '@angular/forms';
import { AccountStatus } from 'src/app/utils/constants';
import { UserHubViewEntity } from 'src/app/models/user-hub-view';
import { PageEntity } from 'src/app/models/types';

const fieldTypeMapper: any = {
  userName: FILTERS_TYPES.TEXT,
  hubName: FILTERS_TYPES.TEXT,
  userEmail: FILTERS_TYPES.TEXT,
  userCellPhone: FILTERS_TYPES.TEXT,
  subType: FILTERS_TYPES.TEXT,
  subValidUntil: FILTERS_TYPES.DATE,
  active: FILTERS_TYPES.BOOLEAN,
  createdOn: FILTERS_TYPES.DATE,
};

@Component({
  selector: 'test-app-users',
  templateUrl: './test-users.component.html',
  styleUrls: ['./test-users.component.scss'],
})
export class TestUsersComponent implements OnInit {
  @ViewChild('testUsersTable') public dataTable: Table | undefined;

  statuses = Object.keys(AccountStatus).map((key: string) => ({
    label: AccountStatus[key],
    value: key,
  }));
  accountStatus = AccountStatus;

  data: UserHubViewEntity[] = [];

  private readonly apiUrl = environment.apiUrl;
  private readonly appUrl = environment.appUrl;

  form: any;
  isLoadingForm: boolean = false;
  isDialogOpen: boolean = false;
  isEditMode: boolean = false;

  isLoading: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 15;
  firstInPage: number = 0;
  totalRecords: number = 200;
  lastQuery: string = '';
  selectedUser: UserHubViewEntity | null = null;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      userId: [''],
      userEmail: [''],
      userName: [''],
      userCellPhone: [''],
      subType: [''],
      hubId: [''],
      hubUrlHandler: [''],
      hubEmail: [''],
      hubName: [''],
      hubPhoneNumber: [''],
      subExpired: [false],
      active: [false],
      subLastRenew: [null],
      subValidUntil: [null],
      createdOn: [null],
    });
  }

  private setForm(user: UserHubViewEntity) {
    this.selectedUser = user;
    this.form.patchValue({ userId: user.userId });
    this.form.patchValue({ userEmail: user.userEmail });
    this.form.patchValue({ userName: user.userName });
    this.form.patchValue({ userCellPhone: user.userCellPhone });
    this.form.patchValue({ subType: user.subType });
    this.form.patchValue({ hubId: user.hubId });
    this.form.patchValue({ hubUrlHandler: user.hubUrlHandler });
    this.form.patchValue({ hubEmail: user.hubEmail });
    this.form.patchValue({ hubName: user.hubName });
    this.form.patchValue({ hubPhoneNumber: user.hubPhoneNumber });
    this.form.patchValue({ active: user.active });
    this.form.patchValue({ subExpired: user.subExpired });
    this.form.patchValue({
      subLastRenew: user.subLastRenew ? new Date(user.subLastRenew) : null,
    });
    this.form.patchValue({
      subValidUntil: user.subValidUntil ? new Date(user.subValidUntil) : null,
    });
    this.form.patchValue({
      createdOn: user.createdOn ? new Date(user.createdOn) : null,
    });
    this.form.markAsPristine();
  }

  getFormFieldValue(field: string): any {
    return this.form.get(field).value;
  }

  hasFormErrors() {
    return !this.form.valid;
  }

  fieldErrors(field: string) {
    const controlState = this.form.controls[field];
    return controlState.dirty && controlState.errors
      ? controlState.errors
      : null;
  }

  loadData(event: LazyLoadEvent | any) {
    this.isLoading = true;
    this.firstInPage = event.first || 0;
    this.pageSize = event.rows || 0;
    this.pageIndex =
      this.pageSize > 0 ? Math.floor(this.firstInPage / this.pageSize) : 0;
    if (
      event.filters.subType &&
      event.filters.subType[0].value === AccountStatus.EXPIRED
    ) {
      event.filters.subType[0].value = AccountStatus.PREMIUM;
      event.filters.subExpired = [
        { value: true, matchMode: 'contains', operator: 'and' },
      ];
    }

    const testEmailQuery: string = environment.testUsers.reduce((prevQuery: string, testEmail: string) => {
      if (prevQuery) {
        prevQuery += ' OR ';
      }
      prevQuery += `userEmail:'${testEmail}'`;
      return prevQuery;
    }, '');
    const querySearch: string = createQuery(event, fieldTypeMapper, `(${testEmailQuery})`);

    const URL = `${this.apiUrl}/userhubview/v1/search?${querySearch}page=${this.pageIndex}&size=${this.pageSize}`;
    this.lastQuery = URL;
    this.search(URL);
  }

  async search(url: string) {
    const el = document.getElementsByClassName('page-container')[0];
    if (el) {
      el.scrollTop = 0;
    }

    try {
      
      const response: PageEntity<UserHubViewEntity> = await this.httpService.doGet(url).toPromise();
      if (response) {
        this.data = response.content.map((item: any) => {
          return {
            ...item,
            subType: this.getSubStatus(item.subType, item.subExpired),
          };
        });

        this.totalRecords = response.totalElements;
      } else {
        this.data = [];
        this.totalRecords = 0;
        this.firstInPage = 0;
        this.pageIndex = 0;
      }
      this.isLoading = false;
    } catch (error: any) {
      this.data = [];
      this.totalRecords = 0;
      this.firstInPage = 0;
      this.pageIndex = 0;
      this.isLoading = false;
      console.log('Error: ', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      });
    }
  }

  async getExtraData() {
    if (!this.data || this.data.length === 0) return;
    const querySearch = [];
    for (const user of this.data) {
      if (querySearch.length === 0) {
        querySearch.push('querySearch=(');
      } else {
        querySearch.push(' OR ');
      }
      querySearch.push(`userId:'${user.userId}'`);
    }
    querySearch.push(')');

    const URL = `${this.apiUrl}/hubs/v1/search?${querySearch.join(
      ''
    )}&size=1000`;
    try {
      const response = await this.httpService.doGet(URL).toPromise();
      if (response) {
        const hubsByIds = response.content.reduce(
          (previousValue: any, currentValue: any) => {
            return {
              ...previousValue,
              [currentValue.userId]: currentValue,
            };
          },
          {}
        );
        this.data = this.data.map((item: any) => {
          const hub = hubsByIds[item.userId];
          if (!hub) return item;
          return {
            ...item,
            hubId: hub.id,
            handler: hub.urlHandler,
            hubName: hub.name,
          };
        });
      }
    } catch (error: any) {
      console.log('Error: ', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      });
    }
  }

  refreshTable() {
    this.search(this.lastQuery);
  }

  clear(table: Table) {
    table.clear();
  }

  openNew() {
    this.form.reset();
    this.isEditMode = true;
    this.isDialogOpen = true;
  }

  viewItem(user: any) {
    this.form.reset();
    this.setForm(user);
    this.form.disable();
    this.isEditMode = false;
    this.isDialogOpen = true;
  }

  editItem(user: any) {
    this.form.reset();
    this.setForm(user);
    this.form.disable();
    this.form.get('active').enable();
    this.isEditMode = true;
    this.isDialogOpen = true;
  }

  tryToSaveItem() {
    const isActive = this.form.get('active').value;
    if (this.selectedUser?.active === isActive) return;
    this.confirmationService.confirm({
      message: isActive
        ? 'Are you sure you want to activate this user?'
        : 'Are you sure you want to desactivate this user?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.saveItem();
      },
    });
  }

  async saveItem() {
    this.isLoadingForm = true;
    const isActive = this.form.get('active').value;
    const URL = `${this.apiUrl}/admin-center/user-management/v1?userId=${
      this.form.get('userId').value
    }`;

    try {
      await this.httpService
        .doPost(URL, {
          active: isActive,
          userId: this.form.get('userId').value,
        })
        .toPromise();
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: isActive ? 'User activated' : 'User desactivated',
        life: 3000,
      });
      this.isLoadingForm = false;
      this.hideDialog();
      this.refreshTable();
    } catch (error: any) {
      this.isLoadingForm = false;
      console.log('Error: ', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      });
    }
  }

  tryToResetUser(user: UserHubViewEntity) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete the user ${user.userEmail}?`,
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.resetUser(user);
      },
    });
  }

  async resetUser(user: UserHubViewEntity) {
    this.isLoadingForm = true;
    const URL = `${this.apiUrl}/users/v1/reset-user/${
      user.userEmail
    }`;

    try {
      await this.httpService
        .doPost(URL, {})
        .toPromise();
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: `Test User ${user.userEmail} was deleted`,
        life: 3000,
      });
      this.isLoadingForm = false;
      this.hideDialog();
      this.refreshTable();
    } catch (error: any) {
      this.isLoadingForm = false;
      console.log('Error: ', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      });
    }
  }

  hideDialog() {
    this.isDialogOpen = false;
    this.isEditMode = false;
  }

  getSubStatus(subType: String, subExpired: boolean) {
    if (subType === AccountStatus.PREMIUM && subExpired) {
      return AccountStatus.EXPIRED;
    }
    return subType;
  }

  getHubUrl(item: any) {
    return `${this.appUrl}/${item.hubUrlHandler}/client-view?hubId=${item.hubId}`;
  }


}
