<div class="card">
  <h5 class="page-title">Promo Code</h5>
  <p-table
    #usersTable
    [value]="data"
    [columns]="cols"
    dataKey="id"
    [rows]="pageSize"
    [first]="firstInPage"
    [totalRecords]="totalRecords"
    [rowsPerPageOptions]="[15, 30, 60, 100, totalRecords]"
    [showCurrentPageReport]="true"
    [loading]="isLoading"
    styleClass="p-datatable-customers p-datatable-gridlines"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [globalFilterFields]="['code', 'adminUserEmail', 'adminUserName']"
    [lazy]="true"
    (onLazyLoad)="loadData($event)"
    [autoLayout]="true"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <button type="button" pButton pRipple icon="pi pi-file-o" (click)="usersTable.exportCSV()" class="p-mr-2 button-edit" pTooltip="CSV" tooltipPosition="bottom" label="Export CSV"></button>
        <button
          pButton
          pRipple
          label="New"
          icon="pi pi-plus"
          class="p-button-success p-mr-2 button-create"
          (click)="openNew()"
        ></button>
        <!--<button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(usersTable)"></button>-->
        <span class="p-input-icon-left p-ml-auto">
          <i class="pi pi-search"></i>
          <input
            pInputText
            type="text"
            (input)="usersTable.filterGlobal($event.target, 'contains')"
            placeholder="Search keyword"
          />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="code">
          <div class="p-d-flex p-jc-between p-ai-center">
            Code
            <p-sortIcon field="code"></p-sortIcon>
            <p-columnFilter
              type="text"
              field="code"
              display="menu"
              [maxConstraints]="1"
              [showOperator]="false"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="daysGranted">
          <div class="p-d-flex p-jc-between p-ai-center">
            Days Granted
            <p-sortIcon field="daysGranted"></p-sortIcon>
            <p-columnFilter
              type="numeric"
              field="daysGranted"
              display="menu"
              [maxConstraints]="1"
              [showOperator]="false"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="usedCount">
          <div class="p-d-flex p-jc-between p-ai-center">
            Used Count
            <p-sortIcon field="usedCount"></p-sortIcon>
            <p-columnFilter
              type="numeric"
              field="usedCount"
              display="menu"
              [maxConstraints]="1"
              [showOperator]="false"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="usedLimit">
          <div class="p-d-flex p-jc-between p-ai-center">
            Used Limit
            <p-sortIcon field="usedLimit"></p-sortIcon>
            <p-columnFilter
              type="numeric"
              field="usedLimit"
              display="menu"
              [maxConstraints]="1"
              [showOperator]="false"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="validUntil">
          <div class="p-d-flex p-jc-between p-ai-center">
            Valid Until
            <p-sortIcon field="validUntil"></p-sortIcon>
            <p-columnFilter
              type="date"
              field="validUntil"
              display="menu"
              [maxConstraints]="1"
              [showOperator]="false"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th pSortableColumn="createdOn">
          <div class="p-d-flex p-jc-between p-ai-center">
            Create On
            <p-sortIcon field="createdOn"></p-sortIcon>
            <p-columnFilter
              type="date"
              field="createdOn"
              display="menu"
              [maxConstraints]="1"
              [showOperator]="false"
              class="p-ml-auto"
            ></p-columnFilter>
          </div>
        </th>
        <th>
          <div class="p-d-flex p-jc-between p-ai-center">
            Active
            <p-columnFilter
              type="boolean"
              field="active"
              display="menu"
              [maxConstraints]="1"
              [showOperator]="false"
            ></p-columnFilter>
          </div>
        </th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>
          <span>{{ item.code }}</span>
        </td>
        <td>
          <span>{{ item.daysGranted }}</span>
        </td>
        <td>
          <span>{{ item.usedCount }}</span>
        </td>
        <td>
          <span>{{ item.usedLimit }}</span>
        </td>
        <td>
          <span>{{ item.validUntil | date : "medium" }}</span>
        </td>
        <td>
          <span>{{ item.createdOn | date : "medium" }}</span>
        </td>
        <td class="p-text-center">
          <i
            class="pi"
            [ngClass]="{
              'true-icon pi-check-circle': item.active,
              'false-icon pi-times-circle': !item.active
            }"
          ></i>
        </td>
        <td class="actions-col p-text-center">
          <button
            pButton
            icon="pi pi-eye"
            class="p-mr-4 button-view"
            (click)="viewItem(item)"
          ></button>
          <button
            [disabled]="!item.active"
            pButton
            icon="pi pi-pencil"
            [ngClass]="{
              'button-edit': item.active,
              'button-edit-disabled': !item.active
            }"
            (click)="editItem(item)"
          ></button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="8">No promo code found.</td>
      </tr>
    </ng-template>
  </p-table>
</div>

<p-dialog
  [(visible)]="isDialogOpen"
  [style]="{ maxWidth: '100%' }"
  [header]="isEditMode ? 'Promo Code' : 'Promo Code Details'"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <div class="p-grid grid-container">
      <form [formGroup]="form" class="p-fluid p-col">
        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="code">Code</label>
            <input
              id="code"
              type="text"
              aria-describedby="code-help"
              pInputText
              formControlName="code"
            />
          </div>
          <div class="p-field p-col">
            <label for="active">Active</label><br />
            <p-inputSwitch
              inputId="active"
              name="active"
              formControlName="active"
            ></p-inputSwitch>
          </div>
        </div>

        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="daysGranted">Days Granted</label>
            <input
              id="daysGranted"
              type="number"
              aria-describedby="daysGranted-help"
              pInputText
              formControlName="daysGranted"
            />
          </div>
          <div class="p-field p-col">
            <label for="validUntil">Valid Until</label>
            <p-calendar
              inputId="validUntil"
              appendTo="body"
              formControlName="validUntil"
              [showIcon]="true"
              [showTime]="true"
            ></p-calendar>
          </div>
        </div>

        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="usedCount">Used Count</label>
            <input
              id="usedCount"
              type="numeric"
              aria-describedby="usedCount-help"
              pInputText
              formControlName="usedCount"
            />
          </div>
          <div class="p-field p-col">
            <label for="usedLimit">Used Limit</label>
            <input
              id="usedLimit"
              type="numeric"
              aria-describedby="usedLimit-help"
              pInputText
              formControlName="usedLimit"
            />
          </div>
        </div>

        <div class="p-field">
          <label for="adminUserEmail">Admin User Email</label>
          <input
            id="adminUserEmail"
            type="text"
            aria-describedby="adminUserEmail-help"
            pInputText
            formControlName="adminUserEmail"
          />
        </div>

        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="adminUserId">Admin User Id</label>
            <input
              id="adminUserId"
              type="text"
              aria-describedby="adminUserId-help"
              pInputText
              formControlName="adminUserId"
            />
          </div>
          <div class="p-field p-col">
            <label for="adminUserName">Admin User Name</label>
            <input
              id="adminUserName"
              type="text"
              aria-describedby="adminUserName-help"
              pInputText
              formControlName="adminUserName"
            />
          </div>
        </div>

        <div class="p-formgrid p-grid">
          <div class="p-field p-col">
            <label for="createdOn">Created On</label>
            <p-calendar
              inputId="createdOn"
              appendTo="body"
              dataType="string"
              formControlName="createdOn"
              [showIcon]="true"
              [showTime]="true"
            ></p-calendar>
          </div>
          <div class="p-field p-col">
            <label for="updatedOn">Updated On</label>
            <p-calendar
              inputId="updatedOn"
              appendTo="body"
              formControlName="updatedOn"
              [showIcon]="true"
              [showTime]="true"
            ></p-calendar>
          </div>
        </div>
        <div class="p-field">
          <label for="id">ID</label>
          <input
            id="id"
            type="text"
            aria-describedby="id-help"
            pInputText
            formControlName="id"
          />
        </div>
      </form>
      <div class="p-fluid">
        <app-promo-code-user-logs
          [promoCode]="getFormFieldValue('code')"
        ></app-promo-code-user-logs>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      icon="pi pi-check"
      class="p-button-text"
      [disabled]="form.invalid || !form.dirty || isLoadingForm"
      [loading]="isLoadingForm"
      (click)="tryToSaveItem()"
    ></button>
  </ng-template>
</p-dialog>

<p-dialog
  [(visible)]="isCreateMode"
  [style]="{ width: '600px' }"
  header="Create Promo Code"
  [modal]="true"
  styleClass="p-fluid"
>
  <ng-template pTemplate="content">
    <form [formGroup]="form" class="p-fluid">
      <div class="p-formgrid p-grid">
        <div class="p-field p-col">
          <label for="code">Promo Code</label>
          <input
            id="code"
            type="text"
            aria-describedby="code-help"
            pInputText
            formControlName="code"
          />
          <small
            id="code-help"
            class="p-error"
            *ngIf="fieldErrors('code')?.minlength"
            >Promo Code min length is 6.</small
          >
          <small
            id="code-help"
            class="p-error"
            *ngIf="
              !fieldErrors('code')?.minlength && fieldErrors('code')?.pattern
            "
            >Spaces are not allowed</small
          >
        </div>
        <div class="p-field p-col">
          <label for="daysGranted">Days Granted</label>
          <input
            id="daysGranted"
            type="number"
            aria-describedby="daysGranted-help"
            pInputText
            formControlName="daysGranted"
          />
        </div>
      </div>

      <div class="p-formgrid p-grid">
        <div class="p-field p-col">
          <label for="validUntil">Valid Until</label>
          <p-calendar
            inputId="validUntil"
            appendTo="body"
            formControlName="validUntil"
            [showIcon]="true"
          ></p-calendar>
        </div>
        <div class="p-field p-col">
          <label for="usedLimit">Used Limit</label>
          <input
            id="usedLimit"
            type="number"
            aria-describedby="usedLimit-help"
            pInputText
            formControlName="usedLimit"
          />
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      label="Cancel"
      icon="pi pi-times"
      class="p-button-text"
      (click)="hideDialog()"
    ></button>
    <button
      pButton
      pRipple
      label="Save"
      icon="pi pi-check"
      class="p-button-text"
      [disabled]="form.invalid || !form.dirty || isLoadingForm"
      [loading]="isLoadingForm"
      (click)="createItem()"
    ></button>
  </ng-template>
</p-dialog>
<p-confirmDialog [style]="{ width: '450px' }"></p-confirmDialog>
