<div class="card">
    <h5 class="page-title">Promo Code Usage Logs</h5>
    <p-table 
        #promoLogsTable 
        [value]="data"
        [columns]="cols"
        dataKey="id"
        [rows]="pageSize"
        [first]="firstInPage"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[15,30,60,100,totalRecords]"
        [showCurrentPageReport]="true"
        [loading]="isLoading"
        styleClass="p-datatable-promo-logs p-datatable-gridlines"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['promoCode', 'userEmail', 'userName', 'hubName']"
        [lazy]="true"
        (onLazyLoad)="loadData($event)"
        [autoLayout]="true"
    >
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="promoLogsTable.exportCSV()" class="p-mr-2 button-edit" pTooltip="CSV" tooltipPosition="bottom" label="Export CSV"></button>
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="promoLogsTable.filterGlobal($event.target, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="promoCode">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Code
                        <p-sortIcon field="promoCode"></p-sortIcon>
                        <p-columnFilter type="text" field="promoCode" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="userEmail">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        User Email
                        <p-sortIcon field="userEmail"></p-sortIcon>
                        <p-columnFilter type="text" field="userEmail" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="userName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        User Name
                        <p-sortIcon field="userName"></p-sortIcon>
                        <p-columnFilter type="text" field="userName" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="hubName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Hub
                        <p-sortIcon field="hubName"></p-sortIcon>
                        <p-columnFilter type="text" field="hubName" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="promoCodeDays">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Days Granted
                        <p-sortIcon field="promoCodeDays"></p-sortIcon>
                        <p-columnFilter type="numeric" field="promoCodeDays" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="createdOn">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Used On
                        <p-sortIcon field="createdOn"></p-sortIcon>
                        <p-columnFilter type="date" field="createdOn" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>
                    <span>{{item.promoCode}}</span>
                </td>
                <td>
                    <span>{{item.userEmail}}</span>
                </td>
                <td>
                    <a class="link" [href]="getHubUrl(item)" target="_blank">
                        <span>{{item.hubName}}</span>
                    </a>
                </td>
                <td>
                    <span>{{item.hubName}}</span>
                </td>
                <td>
                    <span>{{item.promoCodeDays}}</span>
                </td>
                <td>
                    <span>{{item.createdOn | date:'medium'}}</span>
                </td>
                <td class="actions-col p-text-center">
                    <button pButton icon="pi pi-eye" class="p-mr-4 button-view" (click)="viewItem(item)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">No promo code usage found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="isDialogOpen" [style]="{maxWidth: '100%'}" [header]="isEditMode ? 'Promo Code User' : 'Promo Code Details'" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-grid grid-container">
            <form [formGroup]="form" class="p-fluid p-col">
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col">
                        <label for="promoCode">Promo Code</label>
                        <input id="promoCode" type="text" aria-describedby="promoCode-help" pInputText formControlName="promoCode"/>
                    </div>
                    <div class="p-field p-col">
                        <label for="updatedOn">Days Granted</label>
                        <input id="promoCodeDays" type="number" aria-describedby="promoCodeDays-help" pInputText formControlName="promoCodeDays"/>
                    </div>
                </div>
    
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col">
                        <label for="hubName">Hub Name</label><br>
                        <a id="hubName" class="link" [href]="getHubUrl(selectedItem)" target="_blank">
                            <span>{{selectedItem.hubName}}</span>
                        </a>
                    </div>
                </div>
    
                <div class="p-field">
                    <label for="userEmail">User Email</label>
                    <input id="userEmail" type="text" aria-describedby="userEmail-help" pInputText formControlName="userEmail"/>
                </div>
    
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col">
                        <label for="userId">User Id</label>
                        <input id="userId" type="text" aria-describedby="userId-help" pInputText formControlName="userId"/>
                    </div>
                    <div class="p-field p-col">
                        <label for="userName">User Name</label>
                        <input id="userName" type="text" aria-describedby="userName-help" pInputText formControlName="userName"/>
                    </div>
                </div>
                
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col">
                        <label for="createdOn">Created On</label>
                        <p-calendar inputId="createdOn" appendTo="body" dataType="string" formControlName="createdOn" [showIcon]="true" [showTime]="true"></p-calendar>
                    </div>
                </div>
            </form>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
