import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import{ FILTERS_TYPES, createQuery } from 'src/app/utils/filter';
import { FormBuilder } from '@angular/forms';
import { ENTITY_TYPES, HUB_REPORT_STATUS, ENTITY_TYPES_ENUM } from 'src/app/utils/constants';

const fieldTypeMapper: any = {
  'content.hubName': FILTERS_TYPES.TEXT,
  'content.activityName': FILTERS_TYPES.TEXT,
  'content.entityType': FILTERS_TYPES.TEXT,
  'content.hubUrlHandler': FILTERS_TYPES.TEXT,
  'content.clientName': FILTERS_TYPES.TEXT,
  'content.status': FILTERS_TYPES.TEXT,
  'createdOn': FILTERS_TYPES.DATE,
}

@Component({
  selector: 'app-hub-reports',
  templateUrl: './hub-reports.component.html',
  styleUrls: ['./hub-reports.component.scss']
})
export class HubReportsComponent implements OnInit {
  @ViewChild('itemsTable') public dataTable: Table | undefined;

  entityTypes = ENTITY_TYPES;
  reportStatus = HUB_REPORT_STATUS;
  statuses = Object.keys(HUB_REPORT_STATUS).map((key: string) => ({label: HUB_REPORT_STATUS[key], value: key}),);
  entityTypesOptions = Object.keys(ENTITY_TYPES).map((key: string) => ({label: ENTITY_TYPES[key], value: key}),)

  data = [];

  private readonly apiUrl = environment.apiUrl;
  private readonly appUrl = environment.appUrl;

  form: any;
  isLoadingForm: boolean = false;
  isDialogOpen: boolean = false;
  isEditMode: boolean = false;

  isLoading: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 15;
  firstInPage: number = 0;
  totalRecords: number = 200;
  lastQuery: string = "";
  selectedItem: any;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  cols = [
    { field: 'content.hubName', header: 'Hub Name' },
    { field: 'content.hubUrlHandler', header: 'Hub Handler' },
    { field: 'content.activityName', header: 'Entity Name' },
    { field: 'contentType', header: 'Entity Type' },
    { field: 'content.clientName', header: 'Client Name' },
    { field: 'createdOn', header: 'Create On' },
    { field: 'content.status', header: 'Status' },
  ];

  ngOnInit() {
    this.form = this.fb.group({
      activityId: [""],
      activityName: [""],
      clientEmail: [""],
      clientId: [""],
      clientName: [""],
      clientPhoneNumber: [""],
      clientProfileImage: [""],
      hubId: [""],
      hubName: [""],
      hubUrlHandler: [""],
      id: [""],
      location: [null],
      ownerId: [""],
      questionAnswerMap: [null],
      status: [""],
      totalPrice: [0],
      updatedOn: [null],
      createdOn: [null],
      contentType: [""]
    });
   }

   private setForm(item: any) {
     this.selectedItem = item;

    this.form.patchValue({ clientId: item.clientId });
    this.form.patchValue({ clientEmail: item.clientEmail });
    this.form.patchValue({ clientName: item.clientName });
    this.form.patchValue({ clientProfileImage: item.clientProfileImage });
    this.form.patchValue({ clientPhoneNumber: item.clientPhoneNumber });

    this.form.patchValue({ id: item.id });
    this.form.patchValue({ activityName: item.activityName });
    this.form.patchValue({ categoryName: item.categoryName });
    this.form.patchValue({ activityId: item.activityId });
    this.form.patchValue({ status: item.status});

    this.form.patchValue({ hubId: item.hubId });
    this.form.patchValue({ hubName: item.hubName });
    this.form.patchValue({ hubUrlHandler: item.hubUrlHandler });
    this.form.patchValue({ totalPrice: item.totalPrice });
    this.form.patchValue({ ownerId: item.ownerId });

    this.form.patchValue({ createdOn: item.createdOn ? new Date(item.createdOn) : null });
    this.form.patchValue({ updatedOn: item.updatedOn ? new Date(item.updatedOn) : null });

    this.form.patchValue({ contentType: item.contentType });

    this.form.markAsPristine();
  }

   hasFormErrors() {
     return !this.form.valid;
   }

   fieldErrors(field: string) {
     const controlState = this.form.controls[field];
     return (controlState.dirty && controlState.errors) ? controlState.errors : null;
   }

  dataNormalizer(data: any) {
    return data.map((item: any) => ({
      ...item.content,
      contentType: item.contentType
    }));
  }


  loadData(event: LazyLoadEvent) { 
    this.isLoading = true;
    this.firstInPage = event.first || 0;
    this.pageSize = event.rows || 0;
    this.pageIndex = this.pageSize > 0 ? Math.floor(this.firstInPage / this.pageSize) : 0;
    const querySearch: string = createQuery(event, fieldTypeMapper);
    const URL = `${this.apiUrl}/admin-center/request-report/v1/search?${querySearch}page=${this.pageIndex}&size=${this.pageSize}`;
    this.lastQuery = URL;
    this.search(URL);
  }

  async search(url: string) {
    const el = document.getElementsByClassName('page-container')[0];
    if(el) {
      el.scrollTop = 0;
    }

    try {
      const response = await this.httpService.doGet(url).toPromise();
      if (response) {
        this.data = this.dataNormalizer(response.content);
        this.totalRecords = response.totalElements;
      } else {
        this.data = [];
        this.totalRecords = 0;
        this.firstInPage = 0;
        this.pageIndex = 0;
      }
      this.isLoading = false;
    } catch(error: any) {
      this.data = [];
      this.totalRecords = 0;
      this.firstInPage = 0;
      this.pageIndex = 0;
      this.isLoading = false;
      console.log('Error: ', error);
      this.messageService.add({severity:'error', summary: 'Error', detail: error.message});
    }
  }

  refreshTable() {
    this.search(this.lastQuery);
  }

  clear(table: Table) {
    table.clear();
  }

  openNew() {
    this.form.reset();
    this.isEditMode = true;
    this.isDialogOpen = true;
  }

  viewItem(item: any) {
    this.form.reset();
    this.setForm(item);
    this.form.disable();
    this.isEditMode = false;
    this.isDialogOpen = true;
  }

  editItem(item: any) {
    this.form.reset();
    this.setForm(item);
    this.form.disable();
    this.isEditMode = true;
    this.isDialogOpen = true;
  }

  tryToSaveItem() {
    this.confirmationService.confirm({
        message: 'Are you sure you want to save the changes?',
        header: 'Confirm',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            this.saveItem();
        }
    });
  }

  async saveItem() {
    this.isLoadingForm = true;
    const URL = `${this.apiUrl}/admin-center/abuse/v1`;

    try {
      await this.httpService.doPut(URL, {
        id: this.form.get('id').value,
        userId: this.form.get('userId').value,
        status: this.form.get('status').value
      }).toPromise();
      this.messageService.add({
        severity:'success',
        summary: 'Successful',
        detail: 'Hub order changes saved',
        life: 3000
      });
      this.isLoadingForm = false;
      this.hideDialog();
      this.refreshTable();
    } catch(error: any) {

      this.isLoadingForm = false;
      console.log('Error: ', error);
      this.messageService.add({severity:'error', summary: 'Error', detail: error.message});
    }
  }

  hideDialog() {
    this.isDialogOpen = false;
    this.isEditMode = false;
  }

  getHubUrl(item: any) {
    return `${this.appUrl}/${item.hubUrlHandler}/client-view?hubId=${item.hubId}`;
  }

  getEntityUrl(item: any) {
    switch(item.contentType) {
      case ENTITY_TYPES_ENUM.HUB:
        return `${this.appUrl}/${item.hubUrlHandler}/client-view?hubId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.ACTIVITY_INFO:
        return `${this.appUrl}/${item.hubUrlHandler}/actinfo/client-view?activityId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.REQUEST:
      case ENTITY_TYPES_ENUM.ACTIVITY_REQUEST:
        return `${this.appUrl}/${item.hubUrlHandler}/actrqt/client-view?activityId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.RESERVATION:
      case ENTITY_TYPES_ENUM.ACTIVITY_RESERVATION:
        return `${this.appUrl}/${item.hubUrlHandler}/actrvt/client-view?activityId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.SHOPPING:
      case ENTITY_TYPES_ENUM.ACTIVITY_PRODUCT_LISTING:
        return `${this.appUrl}/${item.hubUrlHandler}/actpl/client-view?activityId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.MENU_REQUES:
      case ENTITY_TYPES_ENUM.ACTIVITY_MENU:
        return `${this.appUrl}/${item.hubUrlHandler}/actmenu/client-view?activityId=${item.activityId}`;
      case ENTITY_TYPES_ENUM.CATEGORY_ACCESS:
      case ENTITY_TYPES_ENUM.CATEGORY:
        return `${this.appUrl}/${item.hubUrlHandler}/category/client-view?categoryId=${item.categoryId}`;
    }
    return null;
  }

}
