
<div class="page-container">
    <div class="card-container">
        <div class="logo-container">
            <img src="assets/img/app/primary-logo-text.png" />
        </div>
        
        <p-card>
            <h3 class="title">Sign In</h3>
    
            <form [formGroup]="form" class="p-fluid">
                
                <div class="p-field">
                    <label for="username">Email</label>
                    <input id="username" type="email" class="p-inputtext-lg" aria-describedby="username-help" pInputText formControlName="username"/>
                    <small id="username-help" class="p-error" *ngIf="fieldErrors('username')?.required">Email is required.</small>
                    <small id="username-help" class="p-error" *ngIf="fieldErrors('username')?.email">Enter a valid email.</small>
                </div>
    
                <div class="p-field">
                    <label for="password">Password</label>
                    <input id="password" type="password" class="p-inputtext-lg" aria-describedby="password-help" pInputText formControlName="password"/>
                    <small id="password-help" class="p-error" *ngIf="fieldErrors('password')?.required">Password is required.</small>
                </div>
            </form>

            <div class="button-container">
                <button pButton class="button-login" label="Login" [loading]="isLoading" [disabled]="hasFormErrors()" (click)="signIn()"></button>
            </div>
    
            
        </p-card> 
    </div>

</div>


