import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import{ FILTERS_TYPES, createQuery } from 'src/app/utils/filter';
import { FormBuilder } from '@angular/forms';
import {  IntervalSubscriptionOptions } from 'src/app/utils/constants';

const fieldTypeMapper: any = {
  'hubName': FILTERS_TYPES.TEXT,
  'userName': FILTERS_TYPES.TEXT,
  'userEmail': FILTERS_TYPES.TEXT,
  'id': FILTERS_TYPES.TEXT,
  'paidAmount': FILTERS_TYPES.NUMERIC,
  'interval': FILTERS_TYPES.TEXT,
  'createdOn': FILTERS_TYPES.DATE,
}

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit {
  @ViewChild('itemsTable') public dataTable: Table | undefined;

  intervalSubscriptionOptionsTypes = Object.keys(IntervalSubscriptionOptions).map((key: string) => ({
    label: IntervalSubscriptionOptions[key],
    value: key,
  }));

  IntervalSubscriptionOptions = IntervalSubscriptionOptions;

  data:any = [];

  private readonly apiUrl = environment.apiUrl;
  private readonly appUrl = environment.appUrl;

  form: any;
  isLoadingForm: boolean = false;
  isDialogOpen: boolean = false;
  isEditMode: boolean = false;

  isLoading: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 15;
  firstInPage: number = 0;
  totalRecords: number = 200;
  lastQuery: string = "";
  selectedItem: any;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  cols = [
    { field: 'hubName', header: 'Hub' },
    { field: 'userName', header: 'User' },
    { field: 'interval', header: 'Plan' },
    { field: 'paidAmount', header: 'Amount' },
    { field: 'id', header: 'Payment' },
    { field: 'createdOn', header: 'Create On' },
  ];

  ngOnInit() {
    this.form = this.fb.group({
      id: [""],
      hubId: [""],
      hubName: [""],
      hubUrlHandler: [""],
      userId: [""],
      userName: [""],
      userEmail: [""],
      paidAmount: [0],
      interval: [""],
      createdOn: [null],
    });
   }

   private setForm(item: any) {
     this.selectedItem = item;

    this.form.patchValue({ id: item.id });
    this.form.patchValue({ hubId: item.hubId });
    this.form.patchValue({ hubName: item.hubName });
    this.form.patchValue({ hubUrlHandler: item.hubUrlHandler });
    this.form.patchValue({ userId: item.userId });
    this.form.patchValue({ userName: item.userName });
    this.form.patchValue({ userEmail: item.userEmail });
    this.form.patchValue({ paidAmount: item.paidAmount });
    this.form.patchValue({ interval: item.interval });
    this.form.patchValue({ createdOn: item.createdOn ? new Date(item.createdOn) : null });

    this.form.markAsPristine();
  }

   hasFormErrors() {
     return !this.form.valid;
   }

   fieldErrors(field: string) {
     const controlState = this.form.controls[field];
     return (controlState.dirty && controlState.errors) ? controlState.errors : null;
   }

  loadData(event: LazyLoadEvent) { 
    this.isLoading = true;
    this.firstInPage = event.first || 0;
    this.pageSize = event.rows || 0;
    this.pageIndex = this.pageSize > 0 ? Math.floor(this.firstInPage / this.pageSize) : 0;
    const querySearch: string = createQuery(event, fieldTypeMapper);
    const URL = `${this.apiUrl}/stripe/admin/v1/search?${querySearch}page=${this.pageIndex}&size=${this.pageSize}`;
    this.lastQuery = URL;
    this.search(URL);
  }

  async search(url: string) {
    const el = document.getElementsByClassName('page-container')[0];
    if(el) {
      el.scrollTop = 0;
    }

    try {
      const response = await this.httpService.doGet(url).toPromise();
      if (response) {
        this.data = response.content;
        this.totalRecords = response.totalElements;
      } else {
        this.data = [];
        this.totalRecords = 0;
        this.firstInPage = 0;
        this.pageIndex = 0;
      }
      this.isLoading = false;
    } catch(error: any) {
      this.data = [];
      this.totalRecords = 0;
      this.firstInPage = 0;
      this.pageIndex = 0;
      this.isLoading = false;
      console.log('Error: ', error);
      this.messageService.add({severity:'error', summary: 'Error', detail: error.message});
    }
  }

  refreshTable() {
    this.search(this.lastQuery);
  }

  clear(table: Table) {
    table.clear();
  }

  openNew() {
    this.form.reset();
    this.isEditMode = true;
    this.isDialogOpen = true;
  }

  viewItem(item: any) {
    this.form.reset();
    this.setForm(item);
    this.form.disable();
    this.isEditMode = false;
    this.isDialogOpen = true;
  }

  hideDialog() {
    this.isDialogOpen = false;
    this.isEditMode = false;
  }

  getHubUrl(item: any) {
    return `${this.appUrl}/${item.hubUrlHandler}/client-view?hubId=${item.hubId}`;
  }

  getStripeSubUrl(item: any) {
    return `https://dashboard.stripe.com/test/subscriptions/${item.id}`;
  }

  getFormFieldValue(field: string): any {
    return this.form.get(field).value;
  }
}
