<div class="card">
    <h5 class="page-title">Abuse Reports</h5>
    <p-table 
        #itemsTable 
        [value]="data"
        [columns]="cols"
        dataKey="userId"
        [rows]="pageSize"
        [first]="firstInPage"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[15,30,60,100,totalRecords]"
        [showCurrentPageReport]="true"
        [loading]="isLoading"
        styleClass="p-datatable-customers p-datatable-gridlines"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['userName', 'userEmail']"
        [lazy]="true"
        (onLazyLoad)="loadData($event)"
        [autoLayout]="true"
    >
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="itemsTable.exportCSV()" class="p-mr-2 button-edit" pTooltip="CSV" tooltipPosition="bottom" label="Export CSV"></button>
                <!--<button pButton pRipple label="New" icon="pi pi-plus" class="p-button-success p-mr-2" (click)="openNew()"></button>-->
                <!--<button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clear(usersTable)"></button>-->
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="itemsTable.filterGlobal($event.target, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Name
                        <p-sortIcon field="name"></p-sortIcon>
                        <p-columnFilter type="text" field="userName" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="userEmail">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Email
                        <p-sortIcon field="userEmail"></p-sortIcon>
                        <p-columnFilter type="text" field="userEmail" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="entityType">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Entity
                        <p-sortIcon field="entityType"></p-sortIcon>
                        <p-columnFilter field="entityType" matchMode="equals" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="entityTypesOptions" (onChange)="filter($event.value)" placeholder="Any">
                                    <ng-template let-option pTemplate="item">
                                        <span [class]="'status-tag entity-' + option.value">{{option.label}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="createdOn">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Create On
                        <p-sortIcon field="createdOn"></p-sortIcon>
                        <p-columnFilter type="date" field="createdOn" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="status">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Status
                        <p-sortIcon field="status"></p-sortIcon>
                        <p-columnFilter field="status" matchMode="equals" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)" placeholder="Any">
                                    <ng-template let-option pTemplate="item">
                                        <span [class]="'status-tag status-' + option.value">{{option.label}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td class="image-cell">
                    <p-avatar [image]="item.userImageProfile" styleClass="p-mr-2" shape="circle"></p-avatar>
                    <span class="image-text">{{item.userName}}</span>
                </td>
                <td>
                    <span>{{item.userEmail}}</span>
                </td>
                <td>
                    <a class="link" [href]="getEntityUrl(item)" target="_blank">
                        <span>{{entityTypes[item.entityType]}}</span>
                    </a>
                </td>
                <td>
                    <span>{{item.createdOn | date:'medium'}}</span>
                </td>
                <td>
                    <span [class]="'status-tag status-' + item.status">{{reportStatus[item.status]}}</span>
                </td>
                <td class="actions-col p-text-center">
                    <button pButton icon="pi pi-eye" class="p-mr-4 button-view" (click)="viewItem(item)"></button>
                    <button pButton icon="pi pi-pencil" class="button-edit" (click)="editItem(item)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">No abuse reports found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="isDialogOpen" [style]="{maxWidth: '600px'}" [header]="isEditMode ? 'Edit Abuse Report' : 'Abuse Report Details'" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
            
        <form [formGroup]="form" class="p-fluid">
            <p-avatar [image]="selectedItem.userImageProfile" size="xlarge" class="avatar-details" styleClass="p-mr-2" shape="circle"></p-avatar> 
            <div class="p-field">
                <label for="userEmail">Email</label>
                <input id="userEmail" type="email" aria-describedby="userEmail-help" pInputText formControlName="userEmail"/>
            </div>

            <div class="p-field">
                <label for="userName">Name</label>
                <input id="userName" type="text" aria-describedby="userName-help" pInputText formControlName="userName"/>
            </div>

            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="userId">User ID</label>
                    <input id="userId" type="text" aria-describedby="userId-help" pInputText formControlName="userId"/>
                </div>
                <div class="p-field p-col">
                    <label for="phoneNumber">Phone</label>
                    <input id="phoneNumber" type="text" aria-describedby="phoneNumber-help" pInputText formControlName="phoneNumber"/>
                </div>
            </div>

            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="entityType">Entity</label><br>
                    <a class="link" [href]="getEntityUrl(selectedItem)" target="_blank">
                        <span>{{entityTypes[selectedItem.entityType]}}</span>
                    </a>     
                </div>
                <div class="p-field p-col">           
                    <label for="status">Status</label><br>
                    <span *ngIf="!isEditMode" [class]="'status-tag status-' + selectedItem.status">{{reportStatus[selectedItem.status]}}</span>
                    <p-dropdown *ngIf="isEditMode" inputId="status" [options]="statuses" formControlName="status">
                        <ng-template let-option pTemplate="item">
                            <span [class]="'status-tag status-' + option.value">{{option.label}}</span>
                        </ng-template>
                    </p-dropdown>     
                </div>
            </div>

            <div class="p-field">
                <label for="message">Message</label>
                <textarea id="message" pInputTextarea formControlName="message" rows="3" cols="20"></textarea>
            </div>

            <div class="p-field">
                <label for="comments">Comment</label>
                <textarea id="comments" pInputTextarea formControlName="comments" rows="3" cols="20"></textarea>
            </div>

            <div class="p-field">
                <label for="entityId">Entity ID</label>
                <input id="entityId" type="text" aria-describedby="entityId-help" pInputText formControlName="entityId"/>
            </div>

            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="createdOn">Created On</label>
                    <p-calendar inputId="createdOn" appendTo="body" dataType="string" formControlName="createdOn" [showIcon]="true" [showTime]="true"></p-calendar>
                </div>
                <div class="p-field p-col">
                    <label for="updatedOn">Updated On</label>
                    <p-calendar inputId="updatedOn" appendTo="body" formControlName="updatedOn" [showIcon]="true" [showTime]="true"></p-calendar>
                </div>
            </div>
        </form>
    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button 
            *ngIf="isEditMode"
            pButton
            pRipple
            label="Save"
            icon="pi pi-check"
            class="p-button-text"
            [disabled]="form.invalid || !form.dirty || isLoadingForm"
            [loading]="isLoadingForm"
            (click)="tryToSaveItem()"
        ></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
