export const environment = {
  production: false,
  apiUrl: 'https://staging1.tinyall.app/api',
  appUrl: 'https://staging1.tinyall.app',
  firebaseConfig: {
    apiKey: "AIzaSyC6febKszCf4XvTQaCRUMVhA5WfCnpIQcA",
    authDomain: "tinyall-staging1.firebaseapp.com",
    databaseURL: "https://tinyall-staging1.firebaseio.com",
    projectId: "tinyall-staging1",
    storageBucket: "tinyall-staging1.appspot.com",
    messagingSenderId: "458757904828",
    appId: "1:458757904828:web:0650e9318efd3951bdb3b9",
    measurementId: "G-5K2TZESY4Z"
  },
  testUsers: [
    'mrijo@outlook.com',
    'kgb.tinyall.com@hotmail.com',
    'danielbrandaereo+1@gmail.com',
    'tinyalltest5@gmail.com',
    'tinyalltest6@gmail.com',
    'tinyalltest1@gmail.com',
  ],
  mapbox: {
    accessToken: 'pk.eyJ1IjoibW9ua2V5b3BlcmF0b3IiLCJhIjoiY2tmdTYyMXNnMGc1dzJzcDl4bGVjejRtZSJ9.9_qRy-KtQRAz73dwNzP05Q'
  },
};

