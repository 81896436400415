import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-page-layout',
  templateUrl: './page-layout.component.html',
  styleUrls: ['./page-layout.component.scss'],
})
export class PageLayoutComponent {
  public isExpanded = false;

  items = [
    {
      label: 'Dashboard',
      icon: 'pi pi-fw pi-chart-line',
      routerLink: ['dashboard'],
    },
    environment.production
      ? { label: 'Users', icon: 'pi pi-fw pi-users', routerLink: ['users'] }
      : {
          label: 'Users',
          icon: 'pi pi-fw pi-file',
          items: [
            {
              label: 'Users',
              icon: 'pi pi-fw pi-users',
              routerLink: ['users'],
            },
            {
              label: 'Test Users',
              icon: 'pi pi-fw pi-file',
              routerLink: ['testusers'],
            },
          ],
        },

    {
      label: 'Abuse Reports',
      icon: 'pi pi-fw pi-exclamation-triangle',
      routerLink: ['abuses'],
    },
    {
      label: 'Hubs',
      icon: 'pi pi-fw pi-file',
      items: [
        { label: 'Hubs', icon: 'pi pi-fw pi-list', routerLink: ['hubs'] },
        {
          label: 'Hub Orders',
          icon: 'pi pi-fw pi-file',
          routerLink: ['orders'],
        },
        { label: 'Subscriptions', icon: 'pi pi-fw pi-money-bill', routerLink: ['subscriptions'] },
      ],
    },
    {
      label: 'Promo Codes',
      icon: 'pi pi-fw pi-ticket',
      items: [
        { label: 'Codes', icon: 'pi pi-fw pi-ticket', routerLink: ['promos'] },
        {
          label: 'Usage Logs',
          icon: 'pi pi-fw pi-list',
          routerLink: ['promocodelogs'],
        },
      ],
    },
    { label: 'Login Logs', icon: 'pi pi-fw pi-list', routerLink: ['logs'] },
  ];

  isLoading: boolean = false;
  profile: any;

  constructor(public authService: AuthService) {
    const data = localStorage.getItem('user_profile');
    if (data) {
      this.profile = JSON.parse(data);
    }
  }

  public toggleMenu() {
    this.isExpanded = !this.isExpanded;
  }

  public async logout() {
    this.isLoading = false;
    await this.authService.logout();
    this.isLoading = false;
  }
}
