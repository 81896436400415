import { Component, OnInit} from '@angular/core';
import { MessageService } from 'primeng/api';
import { HttpService } from 'src/app/services/http.service';
import { DAYS, getWeekDaysSorted } from 'src/app/utils/constants';
import { normalizeDateTo } from 'src/app/utils/filter';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-users-card',
  templateUrl: './users-card.component.html',
  styleUrls: ['./users-card.component.scss'],
})
export class UsersCardComponent implements OnInit {
  private readonly apiUrl = environment.apiUrl;
  isLoading: boolean = false;
  totalRecords: number = 0;
  oneWeekAgo: Date = new Date();

  data: any = {
    labels: [],
    datasets: [
      {
        label: 'Users',
        fill: true,
        borderColor: '#5d6293',
        backgroundColor: '#5d62938f',
        data: []
      },
    ]
  };

  options = {
    title: {
      display: false,
      text: 'Last Week new users',
      fontSize: 16
    },
    legend: {
      display: false,
      position: 'bottom'
    }
  };
  lastQuery: string = "";
  
  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.oneWeekAgo = new Date();
    this.oneWeekAgo.setDate(this.oneWeekAgo.getDate() - 7);
    this.data.labels = getWeekDaysSorted(this.oneWeekAgo.getDay());
    this.loadData();
  }

  loadData() {
    const URL = `${this.apiUrl}/admin-center/user-management/v1/search?querySearch=createdOn>'${normalizeDateTo(this.oneWeekAgo.toISOString())}'`;
    this.lastQuery = URL;
    this.search(URL);
  }

  async search(url: string) {
    this.isLoading = true;
    try {
      const response = await this.httpService.doGet(url).toPromise();
      if (response) {
        const data = response.content;
        this.totalRecords = data.length;
        this.data.datasets[0].data = this.normalizeDate(data);
        
      } else {
        this.totalRecords = 0;
        this.data.datasets[0].data = [];
      }
      this.isLoading = false;
    } catch(error: any) {
      this.totalRecords = 0;
      this.data.datasets[0].data = [];
      this.isLoading = false;
      console.log('Error: ', error);
      this.messageService.add({severity:'error', summary: 'Error', detail: error.message});
    }
  }

  normalizeDate(data: any) {
    const daysData = data.reduce((acumulator: any, item: any) => {
      const day = DAYS[new Date(item.createdOn).getDay()];
      acumulator[day] += 1; 
      return acumulator;
    },{
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0
    });

    return this.data.labels.map((item: string) => (daysData[item]));
  }
}
