import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ConfirmationService,
  LazyLoadEvent,
  MessageService,
} from 'primeng/api';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { FILTERS_TYPES, createQuery } from 'src/app/utils/filter';
import { FormBuilder, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

const fieldTypeMapper: any = {
  active: FILTERS_TYPES.BOOLEAN,
  adminUserEmail: FILTERS_TYPES.TEXT,
  adminUserName: FILTERS_TYPES.TEXT,
  code: FILTERS_TYPES.TEXT,
  createdOn: FILTERS_TYPES.DATE,
  updatedOn: FILTERS_TYPES.DATE,
  validUntil: FILTERS_TYPES.DATE,
  daysGranted: FILTERS_TYPES.NUMERIC,
  usedCount: FILTERS_TYPES.NUMERIC,
  usedLimit: FILTERS_TYPES.NUMERIC,
};

@Component({
  selector: 'app-promo-codes',
  templateUrl: './promo-codes.component.html',
  styleUrls: ['./promo-codes.component.scss'],
})
export class PromoCodesComponent implements OnInit {
  @ViewChild('promoCodesTable') public dataTable: Table | undefined;

  data = [];

  private readonly apiUrl = environment.apiUrl;

  form: any;
  isLoadingForm: boolean = false;
  isDialogOpen: boolean = false;
  isEditMode: boolean = false;
  isCreateMode: boolean = false;

  isLoading: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 15;
  firstInPage: number = 0;
  totalRecords: number = 200;
  lastQuery: string = '';
  selectedItem: any;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) {}

  cols = [
    { field: 'code', header: 'Code' },
    { field: 'daysGranted', header: 'Days Granted' },
    { field: 'usedCount', header: 'Used Count' },
    { field: 'usedLimit', header: 'Used Limit' },
    { field: 'validUntil', header: 'Valid Until' },
    { field: 'createdOn', header: 'Create On' },
    { field: 'active', header: 'Active' },
  ];

  ngOnInit() {
    this.form = this.fb.group({
      active: [false],
      adminUserEmail: [''],
      adminUserId: [''],
      adminUserName: [''],
      code: ['', [Validators.minLength(6), Validators.pattern(/^\S*$/)]],
      id: [''],
      createdOn: [null],
      updatedOn: [null],
      validUntil: [null, [Validators.required, RxwebValidators.date]],
      daysGranted: [
        0,
        [
          Validators.required,
          RxwebValidators.numeric,
          RxwebValidators.minNumber({
            value: 1,
          }),
        ],
      ],
      usedCount: [0],
      usedLimit: [
        0,
        [
          Validators.required,
          RxwebValidators.numeric,
          RxwebValidators.minNumber({
            value: 1,
          }),
        ],
      ],
    });
  }

  private setForm(item: any) {
    this.selectedItem = item;
    this.form.patchValue({ active: item.active });
    this.form.patchValue({ adminUserEmail: item.adminUserEmail });
    this.form.patchValue({ adminUserId: item.adminUserId });
    this.form.patchValue({ adminUserName: item.adminUserName });
    this.form.patchValue({ code: item.code });
    this.form.patchValue({ id: item.id });
    this.form.patchValue({
      createdOn: item.createdOn ? new Date(item.createdOn) : null,
    });
    this.form.patchValue({
      updatedOn: item.updatedOn ? new Date(item.updatedOn) : null,
    });
    this.form.patchValue({
      validUntil: item.validUntil ? new Date(item.validUntil) : null,
    });
    this.form.patchValue({ daysGranted: item.daysGranted });
    this.form.patchValue({ usedCount: item.usedCount });
    this.form.patchValue({ usedLimit: item.usedLimit });
    this.form.markAsPristine();
  }

  getFormFieldValue(field: string): any {
    return this.form.get(field).value;
  }

  hasFormErrors() {
    return !this.form.valid;
  }

  fieldErrors(field: string) {
    const controlState = this.form.controls[field];
    return controlState.dirty && controlState.errors
      ? controlState.errors
      : null;
  }

  loadData(event: LazyLoadEvent) {
    this.isLoading = true;
    this.firstInPage = event.first || 0;
    this.pageSize = event.rows || 0;
    this.pageIndex =
      this.pageSize > 0 ? Math.floor(this.firstInPage / this.pageSize) : 0;
    const querySearch: string = createQuery(event, fieldTypeMapper);
    const URL = `${this.apiUrl}/admin-center/promo-code/v1/search?${querySearch}page=${this.pageIndex}&size=${this.pageSize}`;
    this.lastQuery = URL;
    this.search(URL);
  }

  async search(url: string) {
    const el = document.getElementsByClassName('page-container')[0];
    if (el) {
      el.scrollTop = 0;
    }

    try {
      const response = await this.httpService.doGet(url).toPromise();
      if (response) {
        this.data = response.content;
        this.totalRecords = response.totalElements;
      } else {
        this.data = [];
        this.totalRecords = 0;
        this.firstInPage = 0;
        this.pageIndex = 0;
      }
      this.isLoading = false;
    } catch (error: any) {
      this.data = [];
      this.totalRecords = 0;
      this.firstInPage = 0;
      this.pageIndex = 0;
      this.isLoading = false;
      console.log('Error: ', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      });
    }
  }

  refreshTable() {
    this.search(this.lastQuery);
  }

  clear(table: Table) {
    table.clear();
  }

  openNew() {
    this.form.reset();
    this.form.disable();
    this.form.get('code').enable();
    this.form.get('daysGranted').enable();
    this.form.get('usedLimit').enable();
    this.form.get('validUntil').enable();
    this.isCreateMode = true;
  }

  viewItem(item: any) {
    this.form.reset();
    this.setForm(item);
    this.form.disable();
    this.isEditMode = false;
    this.isDialogOpen = true;
  }

  editItem(item: any) {
    if (!item.active) return;
    this.form.reset();
    this.setForm(item);
    this.form.disable();
    if (this.selectedItem.active) {
      this.form.get('active').enable();
      this.form.get('daysGranted').enable();
      this.form.get('usedLimit').enable();
      this.form.get('validUntil').enable();
    }
    this.isEditMode = true;
    this.isDialogOpen = true;
  }

  tryToSaveItem() {
    if (!this.selectedItem.active) return;
    this.confirmationService.confirm({
      message: 'Are you sure you want to desactivate this promo code?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.saveItem();
      },
    });
  }

  async saveItem() {
    this.isLoadingForm = true;

    if (this.form.get('active').value === false) {
      const URL = `${this.apiUrl}/admin-center/promo-code/v1/inactive/${
        this.form.get('id').value
      }`;

      try {
        await this.httpService.doPost(URL, {}).toPromise();
        this.messageService.add({
          severity: 'success',
          summary: 'Successful',
          detail: 'Promo Code desactivated',
          life: 3000,
        });
        this.isLoadingForm = false;
        this.hideDialog();
        this.refreshTable();
        return;
      } catch (error: any) {
        this.isLoadingForm = false;
        console.log('Error: ', error);
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: error.message,
        });
        return;
      }
    }

    const URL2 = `${this.apiUrl}/admin-center/promo-code/v1`;

    try {
      await this.httpService
        .doPut(URL2, {
          usedLimit: Number(this.form.get('usedLimit').value),
          daysGranted: Number(this.form.get('daysGranted').value),
          code: this.selectedItem.code,
          validUntil: this.form.get('validUntil').value,
          id: this.selectedItem.id,
        })
        .toPromise();
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Promo Code updated',
        life: 3000,
      });
      this.isLoadingForm = false;
      this.hideDialog();
      this.refreshTable();
    } catch (error: any) {
      this.isLoadingForm = false;
      console.log('Error: ', error);
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      });
    }
  }

  async createItem() {
    this.isLoadingForm = true;

    const URL = `${this.apiUrl}/admin-center/promo-code/v1`;

    try {
      await this.httpService
        .doPost(URL, {
          code: this.form.get('code').value,
          daysGranted: this.form.get('daysGranted').value,
          usedLimit: this.form.get('usedLimit').value,
          validUntil: this.form.get('validUntil').value,
        })
        .toPromise();
      this.messageService.add({
        severity: 'success',
        summary: 'Successful',
        detail: 'Promo Code Created',
        life: 3000,
      });
      this.isLoadingForm = false;
      this.hideDialog();
      this.form.reset();
      this.refreshTable();
    } catch (error: any) {
      this.isLoadingForm = false;
      if (
        error.error &&
        error.error.details &&
        error.error.details.includes('tinyall.promo_code index: code dup key')
      ) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: `The promo  code ${
            this.form.get('code').value
          } already exists`,
        });
        return;
      }
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail: error.message,
      });
    }
  }

  hideDialog() {
    this.isDialogOpen = false;
    this.isEditMode = false;
    this.isCreateMode = false;
  }
}
