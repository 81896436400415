import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import{ FILTERS_TYPES, createQuery } from 'src/app/utils/filter';

const fieldTypeMapper: any = {
  'userEmail': FILTERS_TYPES.TEXT,
  'userName': FILTERS_TYPES.TEXT,
  'promoCode': FILTERS_TYPES.TEXT,
}

@Component({
  selector: 'app-promo-code-user-logs',
  templateUrl: './promo-code-user-logs.component.html',
  styleUrls: ['./promo-code-user-logs.component.scss']
})
export class PromoCodeUserLogsComponent implements OnInit {

  @ViewChild('promoCodeUserLogsTable') public dataTable: Table | undefined;
  @Input() promoCode: any;

  data:any = [];

  private readonly apiUrl = environment.apiUrl;

  isLoading: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 10;
  firstInPage: number = 0;
  totalRecords: number = 200;
  lastQuery: string = "";
  selectedItem: any;
  showTable: boolean = true;

  constructor(
    private httpService: HttpService,
    private messageService: MessageService,
  ) { }

  ngOnInit() {

   }


  async loadData(event: LazyLoadEvent | any) {
    this.isLoading = true;
    this.firstInPage = event.first || 0;
    this.pageSize = event.rows || 0;
    this.pageIndex = this.pageSize > 0 ? Math.floor(this.firstInPage / this.pageSize) : 0;
    const hasMoreFilters: boolean = createQuery(event, fieldTypeMapper) !== '';
    event.filters.promoCode = [{
      value:this.promoCode,
      matchMode:"equals",
      operator:"and",
    }];
    const querySearch: string = createQuery(event, fieldTypeMapper);
    const URL = `${this.apiUrl}/admin-center/promo-code/v1/search-user-promo-code?${querySearch}page=${this.pageIndex}&size=${this.pageSize}`;
    this.lastQuery = URL;
    await this.search(URL);
    if(!hasMoreFilters && this.data.length === 0) {
      this.showTable = false;
    }
  }

  async search(url: string) {
    const el = document.getElementsByClassName('page-container')[0];
    if(el) {
      el.scrollTop = 0;
    }
    
    try {
      const response = await this.httpService.doGet(url).toPromise();
      if (response) {
        this.data = response.content;
        this.totalRecords = response.totalElements;
      } else {
        this.data = [];
        this.totalRecords = 0;
        this.firstInPage = 0;
        this.pageIndex = 0;
      }
      this.isLoading = false;
    } catch(error: any) {
      this.data = [];
      this.totalRecords = 0;
      this.firstInPage = 0;
      this.pageIndex = 0;
      this.isLoading = false;
      console.log('Error: ', error);
      this.messageService.add({severity:'error', summary: 'Error', detail: error.message});
    }
  }

  refreshTable() {
    this.search(this.lastQuery);
  }

  clear(table: Table) {
    table.clear();
  }

}
