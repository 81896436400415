<div class="card">
    <h5 class="page-title">Subscriptions</h5>
    <p-table 
        #itemsTable 
        [value]="data"
        [columns]="cols"
        dataKey="id"
        [rows]="pageSize"
        [first]="firstInPage"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[15,30,60,100,totalRecords]"
        [showCurrentPageReport]="true"
        [loading]="isLoading"
        styleClass="p-datatable-customers p-datatable-gridlines"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['hubName', 'userName', 'userEmail', 'hubUrlHandler']"
        [lazy]="true"
        (onLazyLoad)="loadData($event)"
        [autoLayout]="true"
    >
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="itemsTable.exportCSV()" class="p-mr-2 button-edit" pTooltip="CSV" tooltipPosition="bottom" label="Export CSV"></button>
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="itemsTable.filterGlobal($event.target, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="hubName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Hub
                        <p-sortIcon field="hubName"></p-sortIcon>
                        <p-columnFilter type="text" field="hubName" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="userName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        User
                        <p-sortIcon field="userName"></p-sortIcon>
                        <p-columnFilter type="text" field="userName" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="interval">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Plan
                        <p-sortIcon field="interval"></p-sortIcon>
                        <p-columnFilter field="interval" matchMode="equals" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="intervalSubscriptionOptionsTypes" (onChange)="filter($event.value)" placeholder="Any">
                                    <ng-template let-option pTemplate="item">
                                        <span>{{option.label}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="paidAmount">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Amount
                        <p-sortIcon field="paidAmount"></p-sortIcon>
                        <p-columnFilter type="numeric" field="paidAmount" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="id">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Payment
                        <p-sortIcon field="id"></p-sortIcon>
                        <p-columnFilter type="text" field="id" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="createdOn">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Create On
                        <p-sortIcon field="createdOn"></p-sortIcon>
                        <p-columnFilter type="date" field="createdOn" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>
                    <a class="link" [href]="getHubUrl(item)" target="_blank">
                        <span>{{item.hubName}}</span>
                    </a>
                </td>
                <td>
                    <span>{{item.userName}}</span>
                </td>
                <td>
                    <span>{{IntervalSubscriptionOptions[item.interval]}}</span>
                </td>
                <td>
                    <span>{{(item.paidAmount > 0 ? item.paidAmount / 100 : 0) | currency:'DOP' }}</span>
                </td>
                <td>
                    <a class="link" [href]="getStripeSubUrl(item)" target="_blank">
                        <span>{{item.id}}</span>
                    </a>
                </td>
                <td>
                    <span>{{item.createdOn | date:'medium'}}</span>
                </td>
                <td class="actions-col p-text-center">
                    <button pButton icon="pi pi-eye" class="p-mr-4 button-view" (click)="viewItem(item)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="8">No subscription found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="isDialogOpen" [style]="{maxWidth: '600px'}" [header]="isEditMode ? 'Edit Subscription Details' : 'Subscription Details'" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
            
        <form [formGroup]="form" class="p-fluid">

            <div class="p-field" *ngIf="selectedItem?.hubName">           
                <label for="hubName">Hub</label> <br>
                <a class="link" [href]="getHubUrl(selectedItem)" target="_blank">
                    <span>{{selectedItem?.hubName}}</span>
                </a>
            </div>

            <div class="p-field">
                <label for="userName">User</label>
                <input id="userName" type="text" aria-describedby="userName-help" pInputText formControlName="userName"/>
            </div>

            <div class="p-field">
                <label for="userEmail">User Email</label>
                <input id="userEmail" type="email" aria-describedby="userEmail-help" pInputText formControlName="userEmail"/>
            </div>

            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="interval">Plan</label>
                    <br/>
                    <span>{{IntervalSubscriptionOptions[getFormFieldValue('interval')]}}</span>   
                </div>
                <div class="p-field p-col">
                    <label for="paidAmount">Amount</label>
                    <br/>
                    <span>{{(selectedItem?.paidAmount > 0 ? selectedItem?.paidAmount / 100 : 0) | currency:'DOP' }}</span>
                </div>
            </div>

            <div class="p-field" *ngIf="selectedItem?.id">           
                <label for="id">Payment</label> <br>
                <a class="link" [href]="getStripeSubUrl(selectedItem)" target="_blank">
                    <span>{{selectedItem?.id}}</span>
                </a>
            </div>


            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="createdOn">Created On</label>
                    <p-calendar inputId="createdOn" appendTo="body" dataType="string" formControlName="createdOn" [showIcon]="true" [showTime]="true"></p-calendar>
                </div>
            </div>

            <div class="p-field">
                <label for="id">ID</label>
                <input id="id" type="text" aria-describedby="id-help" pInputText formControlName="id"/>
            </div>

            <div class="p-field">
                <label for="userId">User ID</label>
                <input id="userId" type="text" aria-describedby="userId-help" pInputText formControlName="userId"/>
            </div>

            <div class="p-field">
                <label for="hubId">Hub ID</label>
                <input id="hubId" type="text" aria-describedby="hubId-help" pInputText formControlName="hubId"/>
            </div>
        </form>
    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
