<div *ngIf="title && answers && !isEmpty(answers)">
    <h2 class="title">{{title}}</h2>
    <div class="p-field">
        <label for="hidden">Obscure</label><br>
        <p-inputSwitch inputId="hidden" (onChange)="encrypted = !encrypted"></p-inputSwitch>
    </div>
</div>
<div class="question-list-container" *ngIf="answers && !isEmpty(answers)">
    <div>
        <ng-container *ngFor="let questionKey of getQuestions(); let i = index;">
            <div class="card-container">

                    <p class="main-field">
                        {{questionKey}} 
                    </p>

                    <div [ngSwitch]="AnswerClassType[answers[questionKey]._class]">
                        <div *ngSwitchCase="'TEXT'">
                           <p class="text">
                               <span>{{encrypted && answers[questionKey].value && answers[questionKey].hidden ? "•••••••" : answers[questionKey].value}}</span>
                            </p>
                        </div>
                        <div *ngSwitchCase="'NUMBER'">
                            <p class="number">
                                <span>{{encrypted && answers[questionKey].value && answers[questionKey].hidden ? "•••••••" : answers[questionKey].value}}</span>
                            </p>
                        </div>
                        <div *ngSwitchCase="'DATE'">
                            <p class="date"><span>{{answers[questionKey].value | date:'mediumDate'}}</span></p>
                        </div>
                        <div *ngSwitchCase="'TIME'">
                            <p class="time"><span>{{getTime(answers[questionKey].value) | date:'shortTime'}}</span></p>
                        </div>
                        <div *ngSwitchCase="'DATETIME'">
                            <p class="datetime"><span>{{answers[questionKey].value | date:'medium'}}</span></p>
                        </div>

                        <div *ngSwitchCase="'SINGLE_CHOICE'">
                            <p class="single-choice"><span>{{answers[questionKey].value}}</span></p>
                        </div>

                        <div *ngSwitchCase="'SINGLE_CHOICE_PRICE'">
                            <p class="single-choice"><span>{{answers[questionKey].value.option}} - ({{answers[questionKey].value.price | currency:'$'}})</span></p>
                        </div>

                        <div *ngSwitchCase="'GEOPOINT'">
                            <app-location-map [noCard]="true" [mapId]="'map' + i"  [launch]="true" [location]="answers[questionKey].value">
                            </app-location-map>
                        </div>

                        <div *ngSwitchCase="'MULTI_CHOICE'">
                            <p class="multi-choice" *ngFor="let option of answers[questionKey].value"><span>{{option}}</span></p>
                        </div>

                        <div *ngSwitchCase="'MULTI_CHOICE_PRICE'">
                            <p class="multi-choice" *ngFor="let option of answers[questionKey].value"><span>{{option.option}} - ({{option.price | currency:'$'}})</span></p>
                        </div>

                        <div *ngSwitchCase="'IMAGE'">
                            <img class="placeholder-image image" [src]="answers[questionKey].value">
                         </div>

                         <div *ngSwitchCase="'GROUP'">
                            <p class="single-choice"><span>{{answers[questionKey].value}}</span></p>
                        </div>
                        <span *ngSwitchDefault>...</span>
                    </div>
       
                </div>
        </ng-container>
    </div>
</div>