import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
  form: any;
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      username: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required]
    });
   }

   hasFormErrors() {
     return !this.form.valid;
   }

   fieldErrors(field: string) {
     const controlState = this.form.controls[field];
     return (controlState.dirty && controlState.errors) ? controlState.errors : null;
   }

   async signIn() {
     if(!this.form.valid) return;
     this.isLoading = true;
     const rawValues = this.form.getRawValue();
     try {
      await this.authService.signIn(rawValues.username, rawValues.password);
      this.isLoading = false;
     } catch(e) {
      this.isLoading = false;
     }
     
   }

}