<div class="card">
    <h5 class="page-title">Hubs</h5>
    <p-table 
        #itemsTable 
        [value]="data" 
        [columns]="cols"
        dataKey="id"
        [rows]="pageSize"
        [first]="firstInPage"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[15,30,60,100,totalRecords]"
        [showCurrentPageReport]="true"
        [loading]="isLoading"
        styleClass="p-datatable-customers p-datatable-gridlines"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['name', 'urlHandler', 'email']"
        [lazy]="true"
        (onLazyLoad)="loadData($event)"
        [autoLayout]="true"
    >
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="itemsTable.exportCSV()" class="p-mr-2 button-edit" pTooltip="CSV" tooltipPosition="bottom" label="Export CSV"></button>
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="itemsTable.filterGlobal($event.target, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="name">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Hub Name
                        <p-sortIcon field="name"></p-sortIcon>
                        <p-columnFilter type="text" field="name" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="urlHandler">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Hub Handler
                        <p-sortIcon field="urlHandler"></p-sortIcon>
                        <p-columnFilter type="text" field="urlHandler" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="email">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Email
                        <p-sortIcon field="email"></p-sortIcon>
                        <p-columnFilter type="text" field="email" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="createdOn">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Create On
                        <p-sortIcon field="createdOn"></p-sortIcon>
                        <p-columnFilter type="date" field="createdOn" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Active
                        <p-columnFilter type="boolean" field="active" display="menu" [maxConstraints]="1" [showOperator]="false"></p-columnFilter>
                    </div>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>
                    <a class="link" [href]="getHubUrl(item)" target="_blank">
                        <span>{{item.name}}</span>
                    </a>
                </td>
                <td>
                    <a class="link" [href]="getHubUrl(item)" target="_blank">
                        <span>{{item.urlHandler}}</span>
                    </a>
                </td>
                <td>
                    <span>{{item.email}}</span>
                </td>
                <td>
                    <span>{{item.createdOn | date:'medium'}}</span>
                </td>
                <td class="p-text-center">
                    <i class="pi" [ngClass]="{'true-icon pi-check-circle': item.active, 'false-icon pi-times-circle': !item.active}"></i>
                </td>
                <td class="actions-col p-text-center">
                    <button pButton icon="pi pi-eye" class="p-mr-4 button-view" (click)="viewItem(item)"></button>
                    <!--<button pButton icon="pi pi-pencil" class="button-edit" (click)="editItem(item)"></button>-->
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="10">No hubs found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="isDialogOpen" [style]="{maxWidth: '600px'}" [header]="isEditMode ? 'Edit Hub Details' : 'Hub Details'" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
            
        <form [formGroup]="form" class="p-fluid">
            <div class="p-formgrid p-grid">
                <div class="p-field">
                    <label>Icon</label>
                    <p-avatar [image]="selectedItem.icon" size="xlarge" class="avatar-details" styleClass="p-mr-2" shape="squarer"></p-avatar>
                </div>
                <div class="p-field">
                    <label>Image</label>
                    <p-avatar [image]="selectedItem.image" size="xlarge" class="avatar-details" styleClass="p-mr-2" shape="squarer"></p-avatar> 
                </div>
            </div>
            <br/>
            
            <div class="p-field">
                <label for="email">Email</label>
                <input id="email" type="email" aria-describedby="email-help" pInputText formControlName="email"/>
            </div>

            <div class="p-field">
                <label for="name">Name</label>
                <input id="name" type="text" aria-describedby="name-help" pInputText formControlName="name"/>
            </div>

            <div class="p-field">
                <label for="infoText">Description</label>
                <textarea id="infoText" pInputTextarea formControlName="infoText" cols="20"></textarea>
            </div>

            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="reviewRate">Review Rate</label>
                    <input id="reviewRate" type="text" aria-describedby="reviewRate-help" pInputText formControlName="reviewRate"/>
                </div>
                <div class="p-field p-col">
                    <label for="reviewCount">Review Count</label>
                    <input id="reviewCount" type="text" aria-describedby="reviewCount-help" pInputText formControlName="reviewCount"/>
                </div>
                <div class="p-field p-col">
                    <label for="views">Views</label>
                    <input id="views" type="text" aria-describedby="views-help" pInputText formControlName="views"/>
                </div>
            </div>

            <div class="p-field">
                <label for="active">Active</label><br>
                <p-inputSwitch inputId="active" name="active" formControlName="active"></p-inputSwitch>
            </div>

            <div class="p-field">
                <label for="unlisted">Unlisted</label><br>
                <p-inputSwitch inputId="unlisted" name="unlisted" formControlName="unlisted"></p-inputSwitch>
            </div>

            <div class="p-field">
                <label for="id">ID</label>
                <input id="id" type="text" aria-describedby="id-help" pInputText formControlName="id"/>
            </div>

            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="createdOn">Created On</label>
                    <p-calendar inputId="createdOn" appendTo="body" dataType="string" formControlName="createdOn" [showIcon]="true" [showTime]="true"></p-calendar>
                </div>
                <div class="p-field p-col">
                    <label for="updatedOn">Updated On</label>
                    <p-calendar inputId="updatedOn" appendTo="body" formControlName="updatedOn" [showIcon]="true" [showTime]="true"></p-calendar>
                </div>
            </div>
        </form>

        <app-view-question-list 
            *ngIf="selectedItem" 
            withTitle="true"
            title="Answers"
            [answers]="selectedItem.questionAnswerMap">
        </app-view-question-list>

    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button 
            *ngIf="isEditMode"
            pButton
            pRipple
            label="Save"
            icon="pi pi-check"
            class="p-button-text"
            [disabled]="form.invalid || !form.dirty || isLoadingForm"
            [loading]="isLoadingForm"
            (click)="tryToSaveItem()"
        ></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
