import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';
import { AbuseReportsComponent } from './pages/abuse-reports/abuse-reports.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HubReportsComponent } from './pages/hub-reports/hub-reports.component';
import { PromoCodesComponent } from './pages/promo-codes/promo-codes.component';
import { LoginLogsComponent } from './pages/login-logs/login-logs.component';
import { PageLayoutComponent } from './pages/page-layout/page-layout.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { UsersComponent } from './pages/users/users.component';
import { TestUsersComponent } from './pages/test-users/test-users.component';
import { PromoLogsComponent } from './pages/promo-logs/promo-logs.component';
import { HubComponent } from './pages/hub/hub.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';

const routes: Routes = [
  { path: '', redirectTo: '/sign-in', pathMatch: 'full' },
  { path: 'sign-in', component: SignInComponent },
  { 
    path: 'base', component: PageLayoutComponent, 
    canActivate: [AuthGuard], 
    children: [
      { path: 'dashboard', component: DashboardComponent },
      { path: 'users', component: UsersComponent },
      { path: 'testusers', component: TestUsersComponent },
      { path: 'abuses', component: AbuseReportsComponent },
      { path: 'orders', component: HubReportsComponent },
      { path: 'hubs', component: HubComponent },
      { path: 'promos', component: PromoCodesComponent },
      { path: 'promocodelogs', component: PromoLogsComponent },
      { path: 'logs', component: LoginLogsComponent },
      { path: 'subscriptions', component: SubscriptionsComponent },
    ] 
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
