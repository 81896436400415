import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data

  private readonly apiUrl = environment.apiUrl;
  private readonly ID_TOKEN = 'id_token';
  private readonly USER_PROFILE = 'user_profile';
  private readonly EXPIRES_AT = 'expired_at';
  private readonly EXP_TIME =  (60 * 58 * 1000);

  constructor(
    private messageService: MessageService,
    private http: HttpClient, // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,  
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {    
    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {

      } else {

      }
    })
  }

  // Sign in with email/password
  async signIn(email: string, password: string) {
    try {
      const userCredential = await this.afAuth.signInWithEmailAndPassword(email, password);
      
      if (userCredential?.user?.emailVerified !== true) {
        this.messageService.add({severity:'error', summary: 'Error', detail: 'User not verified. Please check your email'});
      } else {
        const user = userCredential?.user;
        const token = await userCredential?.user.getIdToken();
        if (user && token) {
          await this.setSession(user, token);
          const URL = `${this.apiUrl}/admin-center/user-login-log/v1`;
          const httpHeaders = new HttpHeaders(
            {
              'Authorization': 'Bearer ' + token,
              'Accept': 'application/json'
            });
          await this.http.post<any>(URL, {}, { headers: httpHeaders }).toPromise();
          this.ngZone.run(() => {
            this.router.navigate(['base','dashboard']);
          });
        }
      }
    } catch (error) {
      this.messageService.add({severity:'error', summary: 'Error', detail: error.message});
      console.error('Error: ', error);
      this.cleanSession();
    }
  }

  private async setSession(user: any, token: any): Promise<boolean> {
    this.cleanSession();
    localStorage.setItem(this.EXPIRES_AT, String(new Date().getTime() + this.EXP_TIME));
    localStorage.setItem(this.ID_TOKEN, token);

    const verifiedUser = await this.getUser(token).catch(error => console.error('User not found:', error));
    if (verifiedUser) {
      await this.setUserApp(verifiedUser);
      return true;
    } else {
      this.messageService.add({severity:'error', summary: 'Error', detail: 'User not found'});
      console.error('User not found');
      return false;
    }
  }

  private async setUserApp(user: any): Promise<void> {
    localStorage.setItem(this.USER_PROFILE, JSON.stringify(user));
  }

  private getUser(tokenId: string): Promise<any> {
    const httpHeaders = new HttpHeaders(
      {
        'Authorization': 'Bearer ' + tokenId,
        'Accept': 'application/json'
      });
    return this.http.get<any>(this.apiUrl + '/users/v1', { headers: httpHeaders }).toPromise();
  }

  public async logout(): Promise<void> {
    await this.afAuth.signOut();
    this.cleanSession();
    this.router.navigate(['sign-in']);
  }

  public isAuthenticated(): boolean {
    const expiresAtData = localStorage.getItem(this.EXPIRES_AT);
    if(!expiresAtData) return false;
    const expiresAt = JSON.parse(expiresAtData);
    const time = new Date().getTime();
    if (expiresAt && Number(expiresAt) > time) {
      return true;
    }
    this.cleanSession();
    return false;
  }

  private cleanSession() {
    localStorage.removeItem(this.ID_TOKEN);
    localStorage.removeItem(this.USER_PROFILE);
    localStorage.removeItem(this.EXPIRES_AT);
  }

  public getIdToken(): string | null {
    if (!this.isAuthenticated()) { return null; }
    return localStorage.getItem(this.ID_TOKEN);
  }

  public getUserProfile(): any {
    if (!this.isAuthenticated()) { return null; }
    const user = localStorage.getItem(this.USER_PROFILE);
    if (!user) { return null; }
    return JSON.parse(user);
  }

}