<p-table 
    #promoCodeUserLogsTable
    *ngIf="showTable"
    [value]="data" 
    dataKey="id"
    [rows]="pageSize"
    [first]="firstInPage"
    [totalRecords]="totalRecords"
    [showCurrentPageReport]="true"
    [loading]="isLoading"
    styleClass="p-datatable-customers p-datatable-gridlines promo-code-user-logs"
    [paginator]="true"
    [globalFilterFields]="['userName', 'userEmail']"
    [lazy]="true"
    (onLazyLoad)="loadData($event)"
    [autoLayout]="true"
>
    <ng-template pTemplate="caption">
        <div class="p-d-flex">
            <h2 class="table-title">Users</h2>
            <span class="p-input-icon-left p-ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="promoCodeUserLogsTable.filterGlobal($event.target, 'contains')" placeholder="Search keyword" />
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th>User</th>
            <th>Email</th>
            <th>Used On</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
        <tr>
            <td>
                <span>{{item.userName}}</span>
            </td>
            <td>
                <span>{{item.userEmail}}</span>
            </td>
            <td>
                <span>{{item.createdOn | date:'medium'}}</span>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="3">No promo code logs found.</td>
        </tr>
    </ng-template>
</p-table>
