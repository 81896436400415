import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ViewQuestionListComponent } from './view-question-list/view-question-list.component';
import { UsersCardComponent } from './users-card/users-card.component';
import { AbuseReportsCardComponent } from './abuse-reports-card/abuse-reports-card.component';
import { HubReportsCardComponent } from './hub-reports-card/hub-reports-card.component';
import { PromoCodeUsageCardComponent } from './promo-code-usage-card/promo-code-usage-card.component';
import { LocationMapComponent } from './location-map/location-map.component';

@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    SharedModule,
  ],
  declarations: [
    ViewQuestionListComponent,
    UsersCardComponent,
    AbuseReportsCardComponent,
    HubReportsCardComponent,
    PromoCodeUsageCardComponent,
    LocationMapComponent,
  ],
  exports: [
    ViewQuestionListComponent,
    UsersCardComponent,
    AbuseReportsCardComponent,
    HubReportsCardComponent,
    PromoCodeUsageCardComponent,
    LocationMapComponent
  ],
})
export class ComponentsModule { }
