<div class="card">
    <h5 class="page-title">Users</h5>
    <p-table 
        #usersTable 
        [value]="data"
        [columns]="cols"
        dataKey="userId"
        [rows]="pageSize"
        [first]="firstInPage"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[15,30,60,100,totalRecords]"
        [showCurrentPageReport]="true"
        [loading]="isLoading"
        styleClass="p-datatable-customers p-datatable-gridlines"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['userEmail', 'userName', 'userCellPhone', 'hubName']"
        [lazy]="true"
        (onLazyLoad)="loadData($event)"
        [autoLayout]="true"
    >
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="usersTable.exportCSV()" class="p-mr-2 button-edit" pTooltip="CSV" tooltipPosition="bottom" label="Export CSV"></button>
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="usersTable.filterGlobal($event.target, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="userName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Name
                        <p-sortIcon field="userName"></p-sortIcon>
                        <p-columnFilter type="text" field="userName" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="hubName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Hub
                        <p-sortIcon field="hubName"></p-sortIcon>
                        <p-columnFilter type="text" field="hubName" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="userEmail">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Email
                        <p-sortIcon field="userEmail"></p-sortIcon>
                        <p-columnFilter type="text" field="userEmail" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="userCellPhone">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Cell Phone
                        <p-sortIcon field="userCellPhone"></p-sortIcon>
                        <p-columnFilter type="text" field="userCellPhone" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="subType">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Sub Type
                        <p-sortIcon field="subType"></p-sortIcon>
                        <p-columnFilter field="subType" matchMode="equals" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)" placeholder="Any">
                                    <ng-template let-option pTemplate="item">
                                        <span [class]="'status-tag ' + option.value">{{option.label}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="subValidUntil">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Expiration Date
                        <p-sortIcon field="subValidUntil"></p-sortIcon>
                        <p-columnFilter type="date" field="subValidUntil" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="createdOn">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Create On
                        <p-sortIcon field="createdOn"></p-sortIcon>
                        <p-columnFilter type="date" field="createdOn" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Active
                        <p-columnFilter type="boolean" field="active" display="menu" [maxConstraints]="1" [showOperator]="false"></p-columnFilter>
                    </div>
                </th>
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Banned
                        <p-columnFilter type="boolean" field="restricted" display="menu" [maxConstraints]="1" [showOperator]="false"></p-columnFilter>
                    </div>
                </th>
                <th>
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Mature
                        <p-columnFilter type="boolean" field="adultContent" display="menu" [maxConstraints]="1" [showOperator]="false"></p-columnFilter>
                    </div>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
            <tr>
                <td class="image-cell">
                    <p-avatar [image]="user.picture" styleClass="p-mr-2" shape="circle"></p-avatar>
                    <span class="image-text">{{user.userName}}</span>
                </td>
                <td>
                    <a class="link" [href]="getHubUrl(user)" target="_blank">
                        <span>{{user.hubName}}</span>
                    </a>
                </td>
                <td>
                    <span>{{user.userEmail}}</span>
                </td>
                <td>
                    <span>{{user.userCellPhone}}</span>
                </td>
                <td>
                    <span [class]="'status-tag ' + user.subType">{{accountStatus[user.subType]}}</span>
                </td>
                <td>
                    <span>{{user.subValidUntil | date:'medium'}}</span>
                </td>
                <td>
                    <span>{{user.createdOn | date:'medium'}}</span>
                </td>
                <td class="p-text-center">
                    <i class="pi" [ngClass]="{'true-icon pi-check-circle': user.active, 'false-icon pi-times-circle': !user.active}"></i>
                </td>
                <td class="p-text-center">
                    <i class="pi" [ngClass]="{'true-icon pi-check-circle': !user.restricted, 'false-icon pi-times-circle': user.restricted}"></i>
                </td>
                <td class="p-text-center">
                    <i class="pi" [ngClass]="{'true-icon pi-check-circle': !user.adultContent, 'false-icon pi-times-circle': user.adultContent}"></i>
                </td>
                <td class="actions-col p-text-center">
                    <button pButton icon="pi pi-eye" class="p-mr-4 button-view" (click)="viewItem(user)"></button>
                    <button pButton icon="pi pi-pencil" class="button-edit" (click)="editItem(user)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="10">No users found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="isDialogOpen" [style]="{maxWidth: '100%'}" [header]="isEditMode ? 'Edit User' : 'User Details'" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
        <div class="p-grid grid-container">
            <form [formGroup]="form" class="p-fluid p-col">
                <!--<p-avatar [image]="form.get('picture').value" size="xlarge" class="avatar-details" styleClass="p-mr-2" shape="circle"></p-avatar>-->
                <div class="p-field">
                    <label for="email">Email</label>
                    <input id="email" type="email" aria-describedby="email-help" pInputText formControlName="userEmail"/>
                </div>
    
                <div class="p-field">
                    <label for="name">Name</label>
                    <input id="name" type="text" aria-describedby="name-help" pInputText formControlName="userName"/>
                </div>
    
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col">
                        <label for="cellPhone">Phone</label>
                        <input id="cellPhone" type="text" aria-describedby="cellPhone-help" pInputText formControlName="userCellPhone"/>
                    </div>
                </div>
    
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col">
                        <label for="subType">Sub Status</label><br>
                        <span [class]="'status-tag ' + getFormFieldValue('subType')">{{accountStatus[getFormFieldValue('subType')]}}</span>    
                    </div>
                    <div *ngIf="getFormFieldValue('subType') != accountStatus.FREE" class="p-field p-col">
                        <label for="subValidUntil">Expiration Date</label>
                        <p-calendar inputId="subValidUntil" appendTo="body" formControlName="subValidUntil" [showIcon]="true" [showTime]="true"></p-calendar>
                    </div>
                </div>
    
                <div class="p-field" *ngIf="getFormFieldValue('subType') != accountStatus.FREE">
                    <div class="p-field p-col">
                        <label for="subLastRenew">Expiration Date</label>
                        <p-calendar inputId="subLastRenew" appendTo="body" formControlName="subLastRenew" [showIcon]="true" [showTime]="true"></p-calendar>
                    </div>
                </div>
                
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col">
                        <label for="createdOn">Created On</label>
                        <p-calendar inputId="createdOn" appendTo="body" dataType="string" formControlName="createdOn" [showIcon]="true" [showTime]="true"></p-calendar>
                    </div>
                </div>
    
                <!--<div class="p-formgrid p-grid">
                    <div class="p-field p-col">           
                        <p-checkbox inputId="notifiableFromClients" name="notifiableFromClients" formControlName="notifiableFromClients" [binary]="true" label="Notifiable From Clients" ></p-checkbox>
                    </div>
                    <div class="p-field p-col">           
                        <p-checkbox inputId="notifiableFromHubs" name="notifiableFromHubs" formControlName="notifiableFromHubs" [binary]="true" label="Notifiable From Hubs"></p-checkbox>
                    </div>
                </div>
    
                <div class="p-formgrid p-grid">
                    <div class="p-field p-col">           
                        <p-checkbox inputId="notifiableFromPromo" name="notifiableFromPromo" formControlName="notifiableFromPromo" [binary]="true" label="Notifiable From Promo"></p-checkbox>
                    </div>
                    <div class="p-field p-col">           
                        <p-checkbox inputId="termOfServiceAgree" name="termOfServiceAgree" formControlName="termOfServiceAgree" [binary]="true" label="Term Of Service Agree"></p-checkbox>
                    </div>
                </div>-->
    
                <div class="p-field">
                    <label for="active">Active</label><br>
                    <p-inputSwitch inputId="active" name="active" formControlName="active"></p-inputSwitch>
                </div>
                <div class="p-field" *ngIf="selectedUser?.hubId">
                    <div class="p-field p-col">
                        <label for="active">Add/Remove Ban</label><br>
                        <button *ngIf="isEditMode" pButton class="ban-button" [ngClass]="{'button-edit': selectedUser?.restricted, 'button-delete': !selectedUser?.restricted}" (click)="tryToBanUser(selectedUser)">
                            {{selectedUser?.restricted ? 'Remove user ban' : 'Ban user'}}
                        </button>
                        <p-inputSwitch *ngIf="!isEditMode" inputId="restricted" name="restricted" formControlName="restricted"></p-inputSwitch>
                    </div>
                    <div class="p-field p-col">
                        <label for="active">Add/Remove Mature Content Flag</label><br>
                        <button *ngIf="isEditMode" pButton class="ban-button" [ngClass]="{'button-edit': selectedUser?.adultContent, 'button-delete': !selectedUser?.adultContent}" (click)="tryToToggleMatureContent(selectedUser)">
                            {{selectedUser?.adultContent ? 'Remove Mature Content Flag' : 'Add Mature Content Flag'}}
                        </button>
                        <p-inputSwitch *ngIf="!isEditMode" inputId="restricted" name="restricted" formControlName="restricted"></p-inputSwitch>
                    </div>
                </div>
                <div class="p-field">
                    <label for="userId">ID</label>
                    <input id="userId" type="text" aria-describedby="userId-help" pInputText formControlName="userId"/>
                </div>
                <div class="p-field" *ngIf="selectedUser?.hubId">           
                    <label for="hubId">Hub ID</label>
                    <input id="hubId" type="text" aria-describedby="hubId-help" pInputText formControlName="hubId"/>
                </div>
                <div class="p-field" *ngIf="selectedUser?.hubName">           
                    <label for="hubUrl">Hub</label> <br>
                    <a class="link" [href]="getHubUrl(selectedUser)" target="_blank">
                        <span>{{selectedUser?.hubName}}</span>
                    </a>
                </div>
            </form>
            <div class="p-fluid">
                <app-user-promo-code-logs [userId]="getFormFieldValue('userId')"></app-user-promo-code-logs>
            </div>
        </div>
    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button 
            *ngIf="isEditMode"
            pButton
            pRipple
            label="Save"
            icon="pi pi-check"
            class="p-button-text"
            [disabled]="form.invalid || !form.dirty || isLoadingForm"
            [loading]="isLoadingForm"
            (click)="tryToSaveItem()"
        ></button>
    </ng-template>
</p-dialog>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
