import { Component, Input, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import defaultLocation from '../../utils/default-location';
import * as mapboxgl from 'mapbox-gl';

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss']
})
export class LocationMapComponent implements AfterViewInit, OnDestroy {

  @Input() name: any;
  @Input() world: boolean = false;
  @Input() set location(location: any) {
    const coor = location?.coordinates || defaultLocation;
    this.link = `https://www.google.com/maps/search/?api=1&query=${coor[0]},${coor[1]}`;
    this._location = location;
    if (this.mapBox) {
      const coor = this._location?.coordinates || defaultLocation;
      this.mapBox.setCenter([coor[1], coor[0]]);
      this.mapBox.zoomTo(13);
      if (this.marker) {
        this.marker.setLngLat([coor[1], coor[0]]);
        this.marker.remove();
        if (!location) {
          this.mapBox.zoomTo(this.world ? -1 : 8);
        }
        else {
          this.marker.addTo(this.mapBox);
        }
      }
    }
  }

  @Input() launch?: boolean;
  @Input() noCard?: boolean;
  @Input() mapId:any = "map-component";

  @ViewChild('mapLink') mapLinkRef: any;
  @ViewChild('mapElement') mapElement: any;
  private scrollListener: any;
  private scrollableElement: any;
  private isMapVisible?: boolean;



  mapboxlib = (mapboxgl as typeof mapboxgl);
  mapBox?: mapboxgl.Map;
  marker?: mapboxgl.Marker;
  link?: string;
  _location: any;

  constructor() {
    this.mapboxlib.accessToken = environment.mapbox.accessToken;
  }

  ngOnDestroy(): void {
    if (this.scrollListener && this.scrollableElement) {
      document.querySelector("#main-content")?.removeEventListener('scroll', this.scrollListener);
    }
  }

  ngAfterViewInit() {
    this.scrollableElement = document.querySelector("#main-content");
    setTimeout(() => {
      this.showMap(this._location, this.name);
    }, 50);

    if (this.detectAndroidFirefox() && this.scrollableElement) {
      this.forceReRenderWhenMapInView();
    }
  }

  forceReRenderWhenMapInView() {
    this.scrollListener = (e: any) => {
      if (!this.isMapVisible && this.isScrolledIntoView(this.mapElement.nativeElement)) {
        this.isMapVisible = true;
        this.mapBox?.triggerRepaint();
      } else if (!this.isScrolledIntoView(this.mapElement.nativeElement)) {
        this.isMapVisible = false;
      }
    };
    document.querySelector("#main-content")?.addEventListener('scroll', this.scrollListener);
  }


  detectAndroidFirefox() {
    var agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf('firefox') >= 0) {
      if (agent.indexOf("android") >= 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  isScrolledIntoView(el: any) {
    var rect = el.getBoundingClientRect();
    return rect.top < window.innerHeight && rect.bottom >= 0;
  }

  showMap(location: any, name: any): void {
    const coor = location?.coordinates || defaultLocation;
      this.mapBox = new mapboxgl.Map({
        container:this.mapId,
        style: 'mapbox://styles/mapbox/streets-v11',
        zoom: location ? 13 : this.world ? -1 : 8,
        center: [coor[1], coor[0]]
    });

    this.mapBox.scrollZoom.disable();
    this.mapBox.doubleClickZoom.disable();
    this.mapBox.dragPan.disable();
    this.mapBox.dragRotate.disable();

    this.marker = new mapboxgl.Marker()
      .setLngLat([coor[1], coor[0]]);

    if (location) {
      this.marker.addTo(this.mapBox);
    }

    if (name) {
      const popup = new mapboxgl.Popup({ offset: 30, focusAfterOpen: false }).setText(name);
      this.marker.setPopup(popup);
      this.marker.togglePopup();
    }
  }

  launchMap() {
    if (this.launch && this.mapLinkRef) {
      this.mapLinkRef.nativeElement.click();
    }
  }
}
