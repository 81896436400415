<div>
  <p-card>
    <h2 class="title">Last Week Promo Code Usages</h2>
    <div *ngIf="!isLoading" class="p-grid">
      <div class="p-col-3 p-grid p-ai-center vertical-container">
        <div class="p-col">
          <p class="label-title">Total</p>
          <p class="label-total">{{totalRecords}}</p>
        </div>
      </div>
      <div class="p-col-9">
        <p-chart type="line" [data]="data" [options]="options"></p-chart>
      </div>
    </div>
    <div *ngIf="isLoading">
      <div class="p-grid p-ai-center vertical-container p-jc-center">
        <div class="p-col spinner-container">
          <p-progressSpinner></p-progressSpinner>
        </div>
      </div>
    </div>
  </p-card>
</div>