import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ComponentsModule } from './components/components.module';
import { SharedModule } from './shared/shared.module';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { environment } from '../environments/environment';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AuthService } from './services/auth.service';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HttpService } from './services/http.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { HttpClientModule } from '@angular/common/http';
import { MessageService, ConfirmationService } from 'primeng/api';
import { PageLayoutComponent } from './pages/page-layout/page-layout.component';
import { UsersComponent } from './pages/users/users.component';
import { TestUsersComponent } from './pages/test-users/test-users.component';
import { AbuseReportsComponent } from './pages/abuse-reports/abuse-reports.component';
import { HubReportsComponent } from './pages/hub-reports/hub-reports.component';
import { PromoCodesComponent } from './pages/promo-codes/promo-codes.component';
import { LoginLogsComponent } from './pages/login-logs/login-logs.component';
import { PromoCodeUserLogsComponent } from './pages/promo-codes/components/promo-code-user-logs/promo-code-user-logs.component';
import { UserPromoCodeLogsComponent } from './pages/users/components/user-promo-code-logs/user-promo-code-logs.component';
import { PromoLogsComponent } from './pages/promo-logs/promo-logs.component';
import { HubComponent } from './pages/hub/hub.component';
import { SubscriptionsComponent } from './pages/subscriptions/subscriptions.component';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    DashboardComponent,
    PageLayoutComponent,
    UsersComponent,
    TestUsersComponent,
    AbuseReportsComponent,
    HubReportsComponent,
    HubComponent,
    PromoCodesComponent,
    PromoLogsComponent,
    PromoCodeUserLogsComponent,
    UserPromoCodeLogsComponent,
    LoginLogsComponent,
    SubscriptionsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ComponentsModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireAuthModule,
  ],
  providers: [AuthService, HttpService, MessageService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
