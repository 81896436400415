<div class="card">
    <h5 class="page-title">Hub Orders</h5>
    <p-table 
        #itemsTable 
        [value]="data"
        [columns]="cols"
        dataKey="userId"
        [rows]="pageSize"
        [first]="firstInPage"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[15,30,60]"
        [showCurrentPageReport]="true"
        [loading]="isLoading"
        styleClass="p-datatable-customers p-datatable-gridlines"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['hubName', 'hubUrlHandler', 'activityName', 'clientName']"
        [lazy]="true"
        (onLazyLoad)="loadData($event)"
        [autoLayout]="true"
    >
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="itemsTable.filterGlobal($event.target, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="content.hubName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Hub Name
                        <p-sortIcon field="content.hubName"></p-sortIcon>
                        <p-columnFilter type="text" field="content.hubName" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="content.hubUrlHandler">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Hub Handler
                        <p-sortIcon field="content.hubUrlHandler"></p-sortIcon>
                        <p-columnFilter type="text" field="content.hubUrlHandler" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="content.activityName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Entity Name
                        <p-sortIcon field="content.activityName"></p-sortIcon>
                        <p-columnFilter type="text" field="content.activityName" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                
                <th pSortableColumn="contentType">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Entity Type
                        <p-sortIcon field="contentType"></p-sortIcon>
                        <p-columnFilter field="contentType" matchMode="equals" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="entityTypesOptions" (onChange)="filter($event.value)" placeholder="Any">
                                    <ng-template let-option pTemplate="item">
                                        <span [class]="'status-tag entity-' + option.value">{{option.label}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="content.clientName">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Client Name
                        <p-sortIcon field="content.clientName"></p-sortIcon>
                        <p-columnFilter type="text" field="content.clientName" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="createdOn">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Create On
                        <p-sortIcon field="createdOn"></p-sortIcon>
                        <p-columnFilter type="date" field="createdOn" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="content.status">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Status
                        <p-sortIcon field="content.status"></p-sortIcon>
                        <p-columnFilter field="content.status" matchMode="equals" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-dropdown [ngModel]="value" [options]="statuses" (onChange)="filter($event.value)" placeholder="Any">
                                    <ng-template let-option pTemplate="item">
                                        <span [class]="'status-tag status-' + option.value">{{option.label}}</span>
                                    </ng-template>
                                </p-dropdown>
                            </ng-template>
                        </p-columnFilter>
                    </div>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>
                    <a class="link" [href]="getHubUrl(item)" target="_blank">
                        <span>{{item.hubName}}</span>
                    </a>
                </td>
                <td>
                    <a class="link" [href]="getHubUrl(item)" target="_blank">
                        <span>{{item.hubUrlHandler}}</span>
                    </a>
                </td>
                <td>
                    <a class="link" [href]="getEntityUrl(item)" target="_blank">
                        <span>{{item.activityName || item.categoryName }}</span>
                    </a>
                </td>
                <td>
                    <span>{{entityTypes[item.contentType]}}</span>
                </td>
                <td class="image-cell">
                    <p-avatar [image]="item.clientProfileImage" styleClass="p-mr-2" shape="circle"></p-avatar>
                    <span class="image-text">{{item.clientName}}</span>
                </td>
                <td>
                    <span>{{item.createdOn | date:'medium'}}</span>
                </td>
                <td>
                    <span [class]="'status-tag status-' + item.status">{{reportStatus[item.status]}}</span>
                </td>
                <td class="actions-col p-text-center">
                    <button pButton icon="pi pi-eye" class="p-mr-4 button-view" (click)="viewItem(item)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="10">No hub orders found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="isDialogOpen" [style]="{maxWidth: '600px'}" [header]="isEditMode ? 'Edit Hub Order' : 'Hub Order Details'" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
            
        <form [formGroup]="form" class="p-fluid">
            <p-avatar [image]="selectedItem.clientProfileImage" size="xlarge" class="avatar-details" styleClass="p-mr-2" shape="circle"></p-avatar> 
            <div class="p-field">
                <label for="clientEmail">Client Email</label>
                <input id="clientEmail" type="email" aria-describedby="clientEmail-help" pInputText formControlName="clientEmail"/>
            </div>

            <div class="p-field">
                <label for="clientName">Client Name</label>
                <input id="clientName" type="text" aria-describedby="clientName-help" pInputText formControlName="clientName"/>
            </div>

            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="clientId">Client ID</label>
                    <input id="clientId" type="text" aria-describedby="clientId-help" pInputText formControlName="clientId"/>
                </div>
                <div class="p-field p-col">
                    <label for="clientPhoneNumber">Client Phone</label>
                    <input id="clientPhoneNumber" type="text" aria-describedby="clientPhoneNumber-help" pInputText formControlName="clientPhoneNumber"/>
                </div>
            </div>

            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="hubName">Hub Name</label><br>
                    <a id="hubName" class="link" [href]="getHubUrl(selectedItem)" target="_blank">
                        <span>{{selectedItem.hubName}}</span>
                    </a>
                </div>
                <div class="p-field p-col" *ngIf="selectedItem.activityName">
                    <label for="activityName">Activity Name</label><br>
                    <a id="activityName" class="link" [href]="getEntityUrl(selectedItem)" target="_blank">
                        <span>{{selectedItem.activityName}}</span>
                    </a> 
                </div>
                <div class="p-field p-col" *ngIf="selectedItem.categoryName">
                    <label for="activityName">Category Name</label><br>
                    <a id="activityName" class="link" [href]="getEntityUrl(selectedItem)" target="_blank">
                        <span>{{selectedItem.categoryName}}</span>
                    </a> 
                </div>
            </div>

            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="contentType">Request Type</label><br>
                    <p-dropdown inputId="contentType" [options]="entityTypesOptions" formControlName="contentType">
                        <ng-template let-option pTemplate="item">
                            <span [class]="'status-tag entity-' + option.value">{{option.label}}</span>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="p-field p-col">           
                    <label for="status">Status</label><br>
                    <span [class]="'status-tag status-' + selectedItem.status">{{reportStatus[selectedItem.status]}}</span>   
                </div>
            </div>

            <div class="p-field">
                <label for="id">Request ID</label>
                <input id="id" type="text" aria-describedby="id-help" pInputText formControlName="id"/>
            </div>

            <div class="p-formgrid p-grid">
                <div class="p-field p-col">
                    <label for="createdOn">Created On</label>
                    <p-calendar inputId="createdOn" appendTo="body" dataType="string" formControlName="createdOn" [showIcon]="true" [showTime]="true"></p-calendar>
                </div>
                <div class="p-field p-col">
                    <label for="updatedOn">Updated On</label>
                    <p-calendar inputId="updatedOn" appendTo="body" formControlName="updatedOn" [showIcon]="true" [showTime]="true"></p-calendar>
                </div>
            </div>
        </form>

        <app-view-question-list 
            *ngIf="selectedItem" 
            withTitle="true"
            title="Answers"
            [answers]="selectedItem.questionAnswerMap">
        </app-view-question-list>

    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button 
            *ngIf="isEditMode"
            pButton
            pRipple
            label="Save"
            icon="pi pi-check"
            class="p-button-text"
            [disabled]="form.invalid || !form.dirty || isLoadingForm"
            [loading]="isLoadingForm"
            (click)="tryToSaveItem()"
        ></button>
    </ng-template>
</p-dialog>

<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
