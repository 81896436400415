<div class="p-grid">
    <div class="p-col-6">
        <app-users-card></app-users-card>
    </div>
    <div class="p-col-6">
        <app-abuse-reports-card></app-abuse-reports-card>
    </div>
    <div class="p-col-6">
        <app-hub-reports-card></app-hub-reports-card>
    </div>
    <div class="p-col-6">
        <app-promo-code-usage-card></app-promo-code-usage-card>
    </div>
</div>