import { Component, Input} from '@angular/core';
import { AnswerClassType } from '../../models/answer-class-type';

@Component({
  selector: 'app-view-question-list',
  templateUrl: './view-question-list.component.html',
  styleUrls: ['./view-question-list.component.scss'],
})
export class ViewQuestionListComponent {

  readonly AnswerClassType: any = AnswerClassType;

  @Input()
  answers: any;

  @Input()
  title: string = "";

  encrypted = true;

  constructor() {}

  isEmpty(obj: any) {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  getQuestions() {
    return Object.keys(this.answers);
  }

  getMultiChoicePrice(content: any) {
    return Object.keys(content)
  }

  getSingleChoicePrice(inAnswer: any) {
    const key = Object.keys(inAnswer.values)[0];
    return `${key} - (${inAnswer.values[key]})`;
  }

  getTime(time: string) {
    return `2020-00-00T${time}.000Z`
  }

}
