import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import{ FILTERS_TYPES, createQuery } from 'src/app/utils/filter';
import { FormBuilder } from '@angular/forms';

const fieldTypeMapper: any = {
  'email': FILTERS_TYPES.TEXT,
  'date': FILTERS_TYPES.DATE,
  'host': FILTERS_TYPES.TEXT,
  'ip': FILTERS_TYPES.TEXT,
  'userAgent': FILTERS_TYPES.TEXT,  
}

@Component({
  selector: 'app-login-logs',
  templateUrl: './login-logs.component.html',
  styleUrls: ['./login-logs.component.scss']
})
export class LoginLogsComponent implements OnInit {

  @ViewChild('loginLogsTable') public dataTable: Table | undefined;

  data:any[] = [];

  private readonly apiUrl = environment.apiUrl;

  form: any;
  isLoadingForm: boolean = false;
  isDialogOpen: boolean = false;
  isEditMode: boolean = false;

  isLoading: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 15;
  firstInPage: number = 0;
  totalRecords: number = 200;
  lastQuery: string = "";
  selectedItem: any;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  cols = [
    { field: 'email', header: 'Email' },
    { field: 'date', header: 'Date' },
    { field: 'host', header: 'Host' },
    { field: 'ip', header: 'IP' },
    { field: 'userAgent', header: 'User Agent' },
  ];

  ngOnInit() {
    this.form = this.fb.group({
      email: [""],
      userId: [""],
      date: [null],
      host: [""],
      ip: [""],
      userAgent: [""],
    });
  }

  private setForm(item: any) {
    this.selectedItem = item;

    this.form.patchValue({ userId: item.userId });
    this.form.patchValue({ email: item.email });
    this.form.patchValue({ date: item.date ? new Date(item.date) : null });
    this.form.patchValue({ host: item.host });
    this.form.patchValue({ ip: item.ip });
    this.form.patchValue({ userAgent: item.userAgent });
    
    this.form.markAsPristine();
  }

   hasFormErrors() {
     return !this.form.valid;
   }

   fieldErrors(field: string) {
     const controlState = this.form.controls[field];
     return (controlState.dirty && controlState.errors) ? controlState.errors : null;
   }

  loadData(event: LazyLoadEvent) {
    this.isLoading = true;
    this.firstInPage = event.first || 0;
    this.pageSize = event.rows || 0;
    this.pageIndex = this.pageSize > 0 ? Math.floor(this.firstInPage / this.pageSize) : 0;
    const querySearch: string = createQuery(event, fieldTypeMapper);
    const URL = `${this.apiUrl}/admin-center/user-login-log/v1/search?${querySearch}page=${this.pageIndex}&size=${this.pageSize}`;
    this.lastQuery = URL;
    this.search(URL);
  }

  async search(url: string) {
    const el = document.getElementsByClassName('page-container')[0];
    if(el) {
      el.scrollTop = 0;
    }
    
    try {
      const response = await this.httpService.doGet(url).toPromise();
      if (response) {
        const temData = [];
        for(const user of response.content) {
          for(const log of user.logs) {
            temData.push({
              userId: user.userId,
              email: user.email,
              ...log,
            })
          }
        }

        this.data = temData;
        this.totalRecords = response.totalElements;
      } else {
        this.data = [];
        this.totalRecords = 0;
        this.firstInPage = 0;
        this.pageIndex = 0;
      }
      this.isLoading = false;
    } catch(error: any) {
      this.data = [];
      this.totalRecords = 0;
      this.firstInPage = 0;
      this.pageIndex = 0;
      this.isLoading = false;
      console.log('Error: ', error);
      this.messageService.add({severity:'error', summary: 'Error', detail: error.message});
    }
  }

  refreshTable() {
    this.search(this.lastQuery);
  }

  clear(table: Table) {
    table.clear();
  }

  openNew() {
    this.form.reset();
    this.isEditMode = true;
    this.isDialogOpen = true;
  }

  viewItem(item: any) {
    this.form.reset();
    this.setForm(item);
    this.form.disable();
    this.isEditMode = false;
    this.isDialogOpen = true;
  }

  editItem(item: any) {
    this.form.reset();
    this.setForm(item);
    this.form.disable();
    this.isEditMode = true;
    this.isDialogOpen = true;
  }

  tryToSaveItem() {
    this.saveItem();
  }

  async saveItem() {
    this.isLoadingForm = true;
    const isActive = this.form.get('active').value;
    const URL = isActive 
    ? `${this.apiUrl}/admin-center/user-management/v1/active-user?userId=${this.form.get('userId').value}` 
    : `${this.apiUrl}/admin-center/user-management/v1/inactive-user?userId=${this.form.get('userId').value}`;
      
    try {
      await this.httpService.doPost(URL, {}).toPromise();
      this.messageService.add({
        severity:'success',
        summary: 'Successful',
        detail: isActive ? 'User activated' : 'User desactivated',
        life: 3000
      });
      this.isLoadingForm = false;
      this.hideDialog();
      this.refreshTable();
    } catch(error: any) {

      this.isLoadingForm = false;
      console.log('Error: ', error);
      this.messageService.add({severity:'error', summary: 'Error', detail: error.message});
    }
  }

  hideDialog() {
    this.isDialogOpen = false;
    this.isEditMode = false;
  }

}
