<div class="card">
    <h5 class="page-title">Login Logs</h5>
    <p-table 
        #usersTable 
        [value]="data"
        [columns]="cols"
        dataKey="userId"
        [rows]="pageSize"
        [first]="firstInPage"
        [totalRecords]="totalRecords"
        [rowsPerPageOptions]="[15,30,60,100,totalRecords]"
        [showCurrentPageReport]="true"
        [loading]="isLoading"
        styleClass="p-datatable-customers p-datatable-gridlines"
        [paginator]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="['email']"
        [lazy]="true"
        (onLazyLoad)="loadData($event)"
        [autoLayout]="true"
    >
        <ng-template pTemplate="caption">
            <div class="p-d-flex">
                <button type="button" pButton pRipple icon="pi pi-file-o" (click)="usersTable.exportCSV()" class="p-mr-2 button-edit" pTooltip="CSV" tooltipPosition="bottom" label="Export CSV"></button>
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="usersTable.filterGlobal($event.target, 'contains')" placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="email">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Email
                        <p-sortIcon field="email"></p-sortIcon>
                        <p-columnFilter type="text" field="email" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="date">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Date
                        <p-sortIcon field="date"></p-sortIcon>
                        <p-columnFilter type="date" field="date" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="host">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        Host
                        <p-sortIcon field="host"></p-sortIcon>
                        <p-columnFilter type="text" field="host" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="ip">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        IP
                        <p-sortIcon field="ip"></p-sortIcon>
                        <p-columnFilter type="text" field="ip" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="userAgent">
                    <div class="p-d-flex p-jc-between p-ai-center">
                        User Agent
                        <p-sortIcon field="userAgent"></p-sortIcon>
                        <p-columnFilter type="text" field="userAgent" display="menu" [maxConstraints]="1" [showOperator]="false" class="p-ml-auto"></p-columnFilter>
                    </div>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
            <tr>
                <td>
                    <span>{{item.email}}</span>
                </td>
                <td>
                    <span>{{item.date | date:'medium'}}</span>
                </td>
                <td>
                    <span>{{item.host}}</span>
                </td>
                <td>
                    <span>{{item.ip}}</span>
                </td>
                <td>
                    <span>{{item.userAgent}}</span>
                </td>
                <td class="actions-col p-text-center">
                    <button pButton icon="pi pi-eye" class="p-mr-4 button-view" (click)="viewItem(item)"></button>
                </td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="7">No login logs found.</td>
            </tr>
        </ng-template>
    </p-table>
</div>

<p-dialog [(visible)]="isDialogOpen" [style]="{width: '600px'}" [header]="isEditMode ? 'Edit Login Log' : 'Login Log Details'" [modal]="true" styleClass="p-fluid">
    <ng-template pTemplate="content">
            
        <form [formGroup]="form" class="p-fluid">
            <div class="p-field">
                <label for="email">Email</label>
                <input id="email" type="email" aria-describedby="email-help" pInputText formControlName="email"/>
            </div>

            <div class="p-field">
                <label for="date">Date</label>
                <p-calendar inputId="date" appendTo="body" dataType="string" formControlName="date" [showIcon]="true" [showTime]="true"></p-calendar>
            </div>

            <div class="p-field">
                <label for="host">Host</label>
                <input id="host" type="text" aria-describedby="host-help" pInputText formControlName="host"/>
            </div>

            <div class="p-field">
                <label for="ip">IP</label>
                <input id="ip" type="text" aria-describedby="ip-help" pInputText formControlName="ip"/>
            </div>

            <div class="p-field">
                <label for="userAgent">User Agent</label>
                <textarea id="userAget" pInputTextarea formControlName="userAgent" rows="3" cols="20"></textarea>
            </div>

            <div class="p-field">
                <label for="userId">ID</label>
                <input id="userId" type="text" aria-describedby="userId-help" pInputText formControlName="userId"/>
            </div>
        </form>
    </ng-template>
    
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
        <button 
            *ngIf="isEditMode"
            pButton
            pRipple
            label="Save"
            icon="pi pi-check"
            class="p-button-text"
            [disabled]="form.invalid || !form.dirty || isLoadingForm"
            [loading]="isLoadingForm"
            (click)="tryToSaveItem()"
        ></button>
    </ng-template>
</p-dialog>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
