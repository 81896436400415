import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import{ FILTERS_TYPES, createQuery } from 'src/app/utils/filter';
import { FormBuilder, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

const fieldTypeMapper: any = {
  'hubUrlHandler': FILTERS_TYPES.TEXT,
  'hubName': FILTERS_TYPES.TEXT,
  'userEmail': FILTERS_TYPES.TEXT,
  'userName': FILTERS_TYPES.TEXT,
  'promoCode': FILTERS_TYPES.TEXT,
  'createdOn': FILTERS_TYPES.DATE,
  'promoCodeDays': FILTERS_TYPES.NUMERIC,
}

@Component({
  selector: 'app-promo-logs',
  templateUrl: './promo-logs.component.html',
  styleUrls: ['./promo-logs.component.scss']
})
export class PromoLogsComponent implements OnInit {

  @ViewChild('promoLogsTable') public dataTable: Table | undefined;

  data = [];

  private readonly apiUrl = environment.apiUrl;
  private readonly appUrl = environment.appUrl;

  form: any;
  isLoadingForm: boolean = false;
  isDialogOpen: boolean = false;
  isEditMode: boolean = false;
  isCreateMode: boolean = false;

  isLoading: boolean = false;
  pageIndex: number = 0;
  pageSize: number = 15;
  firstInPage: number = 0;
  totalRecords: number = 200;
  lastQuery: string = "";
  selectedItem: any;

  constructor(
    private fb: FormBuilder,
    private httpService: HttpService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService
  ) { }

  cols = [
    { field: 'promoCode', header: 'Code' },
    { field: 'userEmail', header: 'User Email' },
    { field: 'userName', header: 'User Name' },
    { field: 'hubName', header: 'Hub' },
    { field: 'promoCodeDays', header: 'Days Granted' },
    { field: 'createdOn', header: 'Used On' },
  ];

  ngOnInit() {
    this.form = this.fb.group({
      userEmail: [""],
      userId: [""],
      userName: [""],
      hubUrlHandler: [""],
      hubName: [""],
      promoCode: [""],
      createdOn: [null],
      promoCodeDays: [0, [Validators.required, RxwebValidators.numeric]],
    });
   }

   private setForm(item: any) {
     this.selectedItem = item;
    this.form.patchValue({ hubUrlHandler: item.hubUrlHandler });
    this.form.patchValue({ hubName: item.hubName });
    this.form.patchValue({ userEmail: item.userEmail });
    this.form.patchValue({ userId: item.userId });
    this.form.patchValue({ userName: item.userName });
    this.form.patchValue({ promoCode: item.promoCode });
    this.form.patchValue({ createdOn: item.createdOn ? new Date(item.createdOn) : null });
    this.form.patchValue({ promoCodeDays: item.promoCodeDays });

    this.form.markAsPristine();
  }

  getFormFieldValue(field: string): any {
    return this.form.get(field).value;
  }

   hasFormErrors() {
     return !this.form.valid;
   }

   fieldErrors(field: string) {
     const controlState = this.form.controls[field];
     return (controlState.dirty && controlState.errors) ? controlState.errors : null;
   }


  loadData(event: LazyLoadEvent) {
    this.isLoading = true;
    this.firstInPage = event.first || 0;
    this.pageSize = event.rows || 0;
    this.pageIndex = this.pageSize > 0 ? Math.floor(this.firstInPage / this.pageSize) : 0;
    const querySearch: string = createQuery(event, fieldTypeMapper);
    const URL = `${this.apiUrl}/admin-center/promo-code/v1/search-user-promo-code?${querySearch}page=${this.pageIndex}&size=${this.pageSize}`;
    this.lastQuery = URL;
    this.search(URL);
  }

  async search(url: string) {
    const el = document.getElementsByClassName('page-container')[0];
    if(el) {
      el.scrollTop = 0;
    }
    
    try {
      const response = await this.httpService.doGet(url).toPromise();
      if (response) {
        this.data = response.content;
        this.totalRecords = response.totalElements;
      } else {
        this.data = [];
        this.totalRecords = 0;
        this.firstInPage = 0;
        this.pageIndex = 0;
      }
      this.isLoading = false;
    } catch(error: any) {
      this.data = [];
      this.totalRecords = 0;
      this.firstInPage = 0;
      this.pageIndex = 0;
      this.isLoading = false;
      console.log('Error: ', error);
      this.messageService.add({severity:'error', summary: 'Error', detail: error.message});
    }
  }

  refreshTable() {
    this.search(this.lastQuery);
  }

  clear(table: Table) {
    table.clear();
  }

  viewItem(item: any) {
    this.form.reset();
    this.setForm(item);
    this.form.disable();
    this.isDialogOpen = true;
  }


  hideDialog() {
    this.isDialogOpen = false;
    this.isEditMode = false;
    this.isCreateMode = false;
  }

  getHubUrl(item: any) {
    return `${this.appUrl}/${item.hubUrlHandler}/client-view`;
  }

}
